import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import settingHelper from "helpers/settingHelper";
import notificationHelper from "helpers/notificationHelper";
import routerHelper from "helpers/routerHelper";
import attendanceService from "services/attendanceService";
import enumHelper from "helpers/enumHelper";
import queryStringHelper from "helpers/queryStringHelper";
import _ from "underscore";
import attendanceHelper from "helpers/attendanceHelper";
import dispatchService from "services/dispatchService";

@autoinject
export class FieldServiceAttendance {

    public workOrderId: number = 0;
    public readonly: boolean = false;

    public teams: any[] = [];
    public employees: any[] = [];
    public equipments: any;

    public selectedItemToAdd: any = null;

    public totalAttendanceCount: number = 0;
    public employeesTotalAttendanceCount: number = 0;
    public employeesPresentAttendanceCount: number = 0;
    public presentAttendanceCount: number = 0;
    public selectedFilter: number = 0;
    public isProjectResponsible: boolean = false;

    constructor(private readonly i18n: I18N) {
    }

    public activate(params: any): any {
        this.bindViewModel(params.workOrderId, params.q);
        this.loadData();
    }

    public bindViewModel(workOrderId: number, querystring: string): any {
        this.workOrderId = workOrderId;
        this.readonly = queryStringHelper.parseReadonly(querystring);
    }

    public async loadData(): Promise<any> {
        this.bindLoadedData(await attendanceService.listForWorkorderId(this.workOrderId));
    }

    public get loadAvailableAttendances(): any {
        return {
            transport: (params: any, success: (x: any) => void): any => {
                if (!settingHelper.hasDispatchModel()) {
                    notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"));
                    routerHelper.navigateToRoute("Settings");
                } else {
                    this.selectedFilter = params.data.filterSelected;
                    attendanceService.listAvailableAttendancesForWorkorderId(this.workOrderId,
                            this.getSelectedFilterToApi(params.data.filterSelected),
                            settingHelper.getSelectedDispatchModel(),
                            params.data.filter,
                            params.data.page || 1).then(success);
                }
            },
            mapResults: (item: any): any => {
                const attendanceTypes = enumHelper.attendanceTypes();
                switch (this.selectedFilter) {
                case attendanceTypes.EMPLOYEE.id:
                    return {
                        id: "emp-" + item.Id,
                        text: item.Id + " - " + item.FirstName + " " + item.LastName,
                        type: this.selectedFilter,
                        data: item
                    };
                    case attendanceTypes.TEAM.id:
                    return {
                        id: "team-" + item.Id,
                        text: item.Id + " - " + item.Name,
                        data: item,
                        type: this.selectedFilter
                    };
                    case attendanceTypes.EQUIPMENT.id:
                    return {
                        id: "equip-" + item.Code,
                        text: item.Code + " - " + item.Description,
                        data: item,
                        type: this.selectedFilter
                    };
                }
            }
        };
    }

    public getSelectedFilterToApi(id: number): string {
        const attendanceTypes = enumHelper.attendanceTypes();

        switch (id) {
            case attendanceTypes.EMPLOYEE.id:
                return "employees";
            case attendanceTypes.TEAM.id:
                return "teams";
            case attendanceTypes.EQUIPMENT.id:
                return "equipments";
            default:
                return "";
        }
    }

    public bindLoadedData(loadedData: any): any {
        this.totalAttendanceCount = loadedData.length;
        const presentAttendanceCount = _.filter(loadedData, (item: any) => {
            return item.Presence >= enumHelper.attendance().ONSITE;
        }).length;

        loadedData = _.map(loadedData, (item: any) => {
            item.attendanceStatus = item.Presence;
            item.isInactive = item.IsInactive;
            return item;
        });

        const groupAndSortAttendances = attendanceHelper.groupAndSortAttendances(loadedData, true);
        const employeesLoadedData = attendanceHelper.getEmployeesFromGroupedAndSortedAttendances(groupAndSortAttendances);

        this.teams = attendanceHelper.getTeamsFromGroupedAndSortedAttendances(groupAndSortAttendances);
        this.employees = employeesLoadedData;
        this.equipments = attendanceHelper.getEquipmentFromAttendances(loadedData);
        this.presentAttendanceCount = presentAttendanceCount;
    }

    public genUrl(rel: string, additionalParameter?: any, additionalParameter2?: any): any {
        let url = "";
        if (additionalParameter) {
            if (additionalParameter2) {
                url = routerHelper.navigateTo(rel, this.workOrderId, additionalParameter, additionalParameter2);
            } else {
                url = routerHelper.navigateTo(rel, this.workOrderId, additionalParameter);
            }
        } else {
            url = routerHelper.navigateTo(rel, this.workOrderId);
        }

        return url + routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible, readonly: this.readonly });
    }

    public genTimeEntryUrl(route: string): string {
        return this.genUrl(route);
    }

    public genEmployeeUrl(route: string, employeeId: number, dispatchId: number): any {
        return this.genUrl(route, employeeId, dispatchId);
    }

    public addAttendances(list: any[]): any {
        const dataToPost = _.map(list, (item: any) => {
            const attendanceTypes = enumHelper.attendanceTypes();

            return {
                EmployeeId: item.type === attendanceTypes.EMPLOYEE.id ? item.id.replace("emp-", "") : null,
                EquipmentId: item.type === attendanceTypes.EQUIPMENT.id ? item.id.replace("equip-", "") : null,
                TeamId: item.type === attendanceTypes.TEAM.id ? item.id.replace("team-", "") : null
            };
        });

        if (!settingHelper.hasDispatchModel()) {
            notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"));
            routerHelper.navigateToRoute("Settings");
        }

        attendanceService.addAttendanceForWorkorder(this.workOrderId, dataToPost, settingHelper.getSelectedDispatchModel())
            .then((data: any) => {
                if (data.SaveResponse === enumHelper.attendanceAddReturnType().NONE) {
                    notificationHelper.showWarning(this.i18n.tr("msg_AttendanceAdd_TeamNotAvailableAnymore"));
                } else if (data.SaveResponse === enumHelper.attendanceAddReturnType().PARTIAL) {
                    notificationHelper.showWarning(this.i18n.tr("msg_AttendanceAdd_SomeAllTeamMembersNotAvailableAnymore"), "", { timeOut: 0 });
                }

                this.bindLoadedData(data.List);
            });
    }

    public async removeAttendance(item: any): Promise<void> {
        if (await notificationHelper.showConfirmation(this.i18n.tr("msg_DeleteAttendanceConfirmation"))) {
            await dispatchService.delWorkorderDispatch(this.workOrderId, item.DispatchId);
            this.loadData();
        }
    }

    public async unbindEquipment(item: any): Promise<void> {
        if (await notificationHelper.showConfirmation(this.i18n.tr("msg_UnbindEquipmentConfirmationText"))) {
            dispatchService.breakEquipmentLink(item.DispatchId);
            this.loadData();
        }
    }

    public async updateAttendanceStatus_Absent(item: any): Promise<any> {
        const newStatus = item.attendanceStatus === enumHelper.attendance().ABSENT ? enumHelper.attendance().UNDEFINED : enumHelper.attendance().ABSENT;

        if (newStatus === enumHelper.attendance().ABSENT) {
            if (item.HasTimeEntries) {
                if (await notificationHelper.showConfirmation(this.i18n.tr("msg_AbsentConfirmation"))) {
                    routerHelper.navigate(routerHelper.getRelativeUrl("absence", item.DispatchId));
                }
            } else {
                routerHelper.navigate(routerHelper.getRelativeUrl("absence", item.DispatchId));
            }
        } else {
            this.updateAttendanceStatus(item, { Presence: newStatus }, true);
        }
    }

    public updateAttendanceStatus_Present(item: any): any {
        const newStatus = item.attendanceStatus >= enumHelper.attendance().ONSITE ? enumHelper.attendance().UNDEFINED : enumHelper.attendance().ONSITE;
        this.updateAttendanceStatus(item, { Presence: newStatus }, newStatus === enumHelper.attendance().UNDEFINED);
    }

    public async inactiveClicked(item: any): Promise<any> {
        if (item.LinkedDispatchId) {
            await this.unbindEquipment(item);
            await this.setInactive(item.LinkedEquipment.DispatchId, item);
        } else {
            await this.setInactive(item.DispatchId, item);
        }
    }

    public async updateAttendanceStatus(item: any, model: any, validateActivity: boolean): Promise<void> {
        if (validateActivity && item.HasTimeEntries) {
            if (await notificationHelper.showConfirmation(this.i18n.tr("msg_AbsentConfirmation"))) {
                await this.updateAttendanceStatusInDatabase(item, model);
            }
        } else {
            this.updateAttendanceStatusInDatabase(item, model);
        }
    }

    public async updateAttendanceStatusInDatabase(item: any, model: any): Promise<any> {
        await dispatchService.setWorkorderAttendanceStatus(this.workOrderId, item.DispatchId, model);
        this.loadData();
        item.attendanceStatus = model.Presence;
    }

    public async saveInactive(dispatchId: number, inactive: boolean): Promise<void> {
        await dispatchService.setWorkorderInactive(this.workOrderId, dispatchId, inactive);
        //need to retreive inactive item in case of linked equipment that has been unlinked in the process...

        const eq = _.find(this.equipments.Attendances, (equip: any) => {
            return equip.DispatchId === dispatchId;
        });
        eq.isInactive = inactive;
    }

    public async setInactive(dispatchId: number, item: any): Promise<void> {
        if (item.HasTimeEntries) {
            if (await notificationHelper.showConfirmation(this.i18n.tr("msg_AbsentConfirmationEquipment"))) {
                this.saveInactive(dispatchId, !item.isInactive);
            }
        } else {
            this.saveInactive(dispatchId, !item.isInactive);
        }
    }

}
