import { I18N } from "aurelia-i18n";
import { autoinject, observable } from "aurelia-framework";
import templateService from "services/templateService";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";
import wageSettingHelper from "helpers/wageSettingHelper";
import moment from "moment";
import dateHelper from "helpers/dateHelper";
import projectService from "services/projectService";
import dispatchTimesheetService from "services/dispatchTimesheetService";
import { DispatchProjectTimeEntryService } from "services/dispatch-project-time-entry-service";
import tradesService from "services/tradesService";
import commonService from "services/commonService";
import { BonusService } from "services/bonus-service";
import { DefaultBonusApplyOption } from "api/enums/default-bonus-apply-option";
import enumHelper from "helpers/enumHelper";
import _ from "underscore";
import notificationHelper from "helpers/notificationHelper";
import { BonusesHeaderModel } from "api/models/company/timesheet/bonuses-header-model";
import { DefaultBonusParametersModel } from "api/models/company/bonus/default-bonus-parameters-model";
import { TimeListHelper } from "helpers/time-list-helper";
import val from "core/val";
import settingHelper from "helpers/settingHelper";
import { default as ProjectTimeEntryHelper } from "helpers/project-time-entry-helper";
import { WageType, nameof as nameof_WageType } from "api/enums/wage-type";

@autoinject
export class DailyEntryTimeEntryEdit {

    public PAUSE_ACTIVITY_CODE: string = "PAUSE";

    //#region Properties
    public allShifts: any = [];

    public defaultTrade: any;
    public dispatchProjectCode: string = "";
    public dispatchDate: Date = new Date();
    public dispatch: any;

    public readonly: boolean = true;

    @observable
    public duration: number = 0;
    public timeIncrement: number = 0;
    public isProjectResponsible: boolean = false;
    public maxDate: Date = new Date();
    public showSubProject: boolean = false;
    public timeEntryId: any;
    public wageTypes: any;
    public message: string = "";
    public projectWorkSchedule: any = null;
    public activityAvailable: boolean = false;
    public showShift: boolean = false;
    public defaultShift: any = 0;
    public defaultActivityCode: any = null;
    public wage: number[] = [];
    public templateConfigs: any;
    public dispatchTemplateID: string = "";
    public comment: string = "";
    public timeEntryDispatchId: any = null;

    public applyDefaultBonus: boolean = false;
    public bonuses: any;
    public defaultBonuses: any;

    public showEquipmentSection: boolean = false;
    public mustEnterTimeSeparatelyFlag: boolean = false;

    @observable
    public trade: any;
    @observable
    public activity: any;
    @observable
    public shift: any;
    @observable
    public wageType: number = 0;
    @observable
    public startTime!: Date;
    @observable
    public endTime!: Date;
    @observable
    public equipment: any;
    public equipmentActivity: any;
    public equipmentWorkTime: Date = dateHelper.getDate();

    public selectedSubProject: any;
    public selectedShift: any;
    @observable
    public subProject: any;
    public bonusListUpdatedFromInterface: boolean = false;

    public timeOptions: any;

    public documentCommentMaxLength: number = val.get("document.comment", "maxLength");
    public flagCancelDialog: boolean = false;
    public isLoading: boolean = false;

    constructor(private readonly i18n: I18N, private readonly dispatchProjectTimeEntryService: DispatchProjectTimeEntryService, private readonly bonusService: BonusService, private readonly projectTimeEntryHelper: ProjectTimeEntryHelper) {

    }

    public async activate(params: any): Promise<void> {
        this.isLoading = true;

        await this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.id, params.q);
        await this.loadData();

        if (!params.id) {
            await this.setDefaultActivity();
            this.updateBonuses();
        }

        this.isLoading = false;
    }

    public async bindViewModel(dispatchProjectCode: string, dailyEntryDate: string, timeEntryId: string, qryString: string): Promise<any> {
        this.dispatchDate = new Date(dailyEntryDate);
        this.dispatchProjectCode = dispatchProjectCode;
        this.timeEntryId = timeEntryId;

        this.activityAvailable = !templateService.getCurrentTemplateConfigs().ActivityNotAllowedInMobility;
        this.showShift = templateService.getCurrentTemplateConfigs().ProjectTimeEntryMode !== 1;
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(qryString);
        this.readonly = queryStringHelper.parseReadonly(qryString);
        this.dispatch = JSON.parse(decodeURIComponent(routerHelper.getQuerystring(qryString).dispatchIds))[0]; //TODO remove or remove plural
        this.dispatchTemplateID = this.dispatch.DispatchTemplateID;
        this.templateConfigs = await wageSettingHelper.getDispatchTemplateConfigs("Project", this.dispatchTemplateID);
        this.wage = wageSettingHelper.getWageFromTemplateconfigs("Project", this.templateConfigs);

        this.message = this.dispatch.name;

        this.maxDate = moment(dateHelper.addDay(this.dispatchDate, 1)).toDate();
    }

    public async loadData(): Promise<void> {
        this.loadAllShifts();
        this.loadDefaultShift();
        this.loadBonusConfig();

        this.timeIncrement = await templateService.getUserTemplateIncrement();
        this.timeOptions = TimeListHelper.loadTimeList(this.timeIncrement);

        const hasSubProjects: boolean = await projectService.hasSubProjects(this.dispatchProjectCode);
        //if list of seperate equipment > 0 then show
        const seperateTimeEntryEquipment = (await this.dispatchProjectTimeEntryService.GetSeperateTimeLinkedEquipmentDispatches(this.dispatch.id))!;
        this.showEquipmentSection = seperateTimeEntryEquipment.length > 0;

        if (this.timeEntryId) {
            const timeEntry = await dispatchTimesheetService.getById(this.timeEntryId);
            this.initializeProjects(hasSubProjects);
            this.loadTimeEntry(timeEntry);
            this.loadBonuses();
        } else {
            const trade = this.dispatch.isEmployee ? await tradesService.getTradeForDispatch(this.dispatch.id) : null;
            if (trade) {
                this.trade = { id: trade.Code, text: trade.Code + " - " + trade.Description };
            }

            const schedule = projectService.getProjectWorkSchedule(this.dispatchProjectCode);
            const activityTimeService = dispatchTimesheetService.getDefaultTimes(this.dispatchProjectCode, dateHelper.formatDateToSend(this.dispatchDate), [this.dispatch.id]);
            const lastProjectUsed = projectService.getLastProjectUsed(this.dispatchProjectCode, dateHelper.formatDateToSend(this.dispatchDate));

            await Promise.all([schedule, activityTimeService, lastProjectUsed]).then(([prjSchedule, time, lastPrjUsed]: any): any => {
                this.projectWorkSchedule = prjSchedule;

                this.initializeProjects(hasSubProjects, lastPrjUsed);

                if (time.StartTime) {
                    time.StartTime = dateHelper.roundTime(time.StartTime, this.timeIncrement);
                    this.startTime = time.StartTime;
                }

                if (time.EndTime && moment(time.StartTime).diff(time.EndTime, "minutes") < 0) {
                    time.EndTime = dateHelper.roundTime(time.EndTime, this.timeIncrement);
                    this.endTime = time.EndTime;
                    this.updateDuration();
                } else if (!time.EndTime && time.StartTime) {
                    time.EndTime = dateHelper.roundTime(time.StartTime, this.timeIncrement);
                    this.endTime = time.EndTime;
                    this.updateDuration();
                }

            });
        }
    }

    public async loadBonusConfig(): Promise<void> {
        const config = await commonService.getProjectConfigs();
        this.applyDefaultBonus = config.ApplyDefaultBonus === DefaultBonusApplyOption.DuringTransactionEntry;
    }

    public async loadBonuses(): Promise<void> {
        if (this.applyDefaultBonus) {
            if (this.dispatch.isEmployee) {
                const bonuses = (await this.bonusService.GetBonusesForTimeEntry(this.timeEntryId))!;
                this.bonuses = bonuses.BonusesToDisplay;
                this.defaultBonuses = bonuses.DefaultBonuses;
            }
        }
    }

    public async loadAllShifts(): Promise<void> {
        this.allShifts = await commonService.getShifts();
    }

    public async loadDefaultShift(): Promise<void> {
        this.defaultShift = await dispatchTimesheetService.getDefaultShift(this.dispatchProjectCode);
    }

    public async loadTimeEntry(data: any): Promise<any> {
        //HACK: The api return no description for Pauses

        this.comment = data.Comment;

        this.timeEntryDispatchId = data.DispatchId;

        if (data.ActivityCode) {
            const actDesc = (data.ActivityCode === this.PAUSE_ACTIVITY_CODE ? this.i18n.tr("Pause") : data.ActivityDescription);
            this.activity = { id: data.ActivityCode, text: data.ActivityCode + " - " + actDesc };
        }

        if (data.ProjectCode) {
            this.subProject = { id: data.ProjectCode, text: data.ProjectCode + " - " + data.ProjectDescription };
        }

        if (data.TradeCode) {
            this.trade = { id: data.TradeCode, text: data.TradeCode + " - " + data.TradeDescription };
        }

        if (!this.showShift) {
            this.shift = { id: data.Shift, text: "" };
        } else {
            this.setShiftFromList(data.Shift);
        }

        this.startTime = data.StartDateTime;
        this.endTime = data.EndDateTime;

        this.wageType = data.WageType;

        this.mustEnterTimeSeparatelyFlag = data.MustEnterTimeSeparatelyFlag;

        this.updateDuration();
    }

    public initializeProjects(hasPrj: boolean, lastPrjUsed?: any): void {
        this.showSubProject = hasPrj;
        if (lastPrjUsed) {
           this.subProject = { id: lastPrjUsed.Id, text: lastPrjUsed.Id + " - " + lastPrjUsed.Description };
        }
    }

    public setShiftFromList(shiftId: number): any {
        const shift = this.allShifts.find((s: any) => s.Id === shiftId);
        if (shift) {
            this.shift = { id: shift.Id, text: shift.Id + " - " + shift.Description };
        }
    }

    public buildBonusParameters(): DefaultBonusParametersModel {
        return {
            Activity: this.activity ? this.activity.id : null,
            Shift: this.shift ? this.shift.id : null,
            TradeCode: this.trade ? this.trade.id : null,
            HoursSimple: this.wageType === WageType.Simple ? this.duration : 0,
            HoursHalf: this.wageType === WageType.Over ? this.duration : 0,
            HoursDouble: this.wageType === WageType.Double ? this.duration : 0
        } as DefaultBonusParametersModel;
    }

    public buildTimeEntryDto(): any {
        const data: any = {};

        data.TimeEntryId = this.timeEntryId ? this.timeEntryId : -1;

        data.Comment = this.comment;

        data.DispatchId = this.timeEntryDispatchId ? this.timeEntryDispatchId : this.dispatch.id;

        if (this.subProject) {
            //Set the project code if we have it
            data.ProjectCode = this.subProject.id;
        } else {
            //Else pass the dispatchProjectCode and find the construction project server side
            data.DispatchProjectCode = this.dispatchProjectCode;
        }

        if (this.activity) {
            data.ActivityCode = this.activity.id;
            data.ActivityDescription = this.activity.text;
        }

        if (this.trade) {
            data.TradeCode = this.trade.id;
            data.TradeDescription = this.trade.text;
        }

        data.StartTime = dateHelper.getTime(this.startTime);

        if (this.endTime) {
            data.EndTime = dateHelper.getTime(this.endTime);
        }

        data.WageType = this.wageType;

        if (this.shift) {
            data.Shift = this.shift.id;
        }

        if (this.defaultBonuses && this.defaultBonuses.length > 0) {
            data.DefaultBonuses = this.defaultBonuses;
        }

        return data;

    }

    public buildEmployeeEquipmentTimeEntry(): any {
        const data: any = {};
        data.TimeEntryId = -1;

        data.DispatchId = this.equipment.id;

        data.DispatchProjectCode = this.dispatchProjectCode;
        data.ActivityCode = this.equipmentActivity.id;

        if (this.selectedSubProject) {
            data.ProjectCode = this.selectedSubProject.id;
        } else {
            data.DispatchProjectCode = this.dispatchProjectCode;
        }

        data.StartTime = dateHelper.getTime(this.startTime);
        const duration = dateHelper.toHourDecimal(this.equipmentWorkTime);
        data.EndTime = dateHelper.getTime(dateHelper.addHour(this.startTime, duration));

        data.WageType = WageType.Simple;

        data.Shift = this.selectedShift;

        return data;
    }

    public updateDuration(): void {
        if (this.startTime && this.endTime) {
            const duration = dateHelper.getTimeSpanInHour(this.startTime, this.endTime);

            if (this.duration !== duration) {
                this.duration = duration;
            }
        } else {
            this.duration = 0;
        }
    }

    public async updateBonuses(): Promise<void> {

        if (!this.applyDefaultBonus || !this.dispatch.isEmployee) {
            return;
        }

        const params = this.buildBonusParameters();

        if (this.dispatch.id) {
            const bonuses: BonusesHeaderModel = (await this.bonusService.GetInterfaceBonusesForDispatch(this.dispatch.id, params))!;
            this.bonuses = bonuses.BonusesToDisplay;
            this.defaultBonuses = bonuses.DefaultBonuses;
            this.bonusListUpdatedFromInterface = true;
        }

    }

    public async updateBonusesQtiesHours(): Promise<void> {
        if (!this.applyDefaultBonus || !this.dispatch.isEmployee || !this.activity || this.timeEntryId === undefined) {
            return;
        }

        const params = this.buildBonusParameters();

        const bonuses = (await this.bonusService.UpdateBonusesQtiesHoursForTimeEntry(this.timeEntryId, params))!;

        this.bonuses = bonuses.BonusesToDisplay;

    }

    public getAddBonusUrl(id: any): string {
        const params = this.getDefaultQueryStringParameters();
        params.dispatchIds = [this.dispatch.id];

        const queryString = routerHelper.addQuerystring(params);
        const url = id
            ? routerHelper.navigateTo("Project_Detail_Daily_Entry_TimeEntry_Bonus", this.dispatchProjectCode, this.dispatchDate, id)
            : routerHelper.navigateTo("Project_Detail_Daily_Entry_TimeEntry_Bonus", this.dispatchProjectCode, this.dispatchDate);

        return url + queryString;
    }

    public get getShifts(): any {
        return {
            transport: (params: any, success: any): any => {
                return success(this.allShifts);
            }
        };
    }

    public get getProjects(): any {
        return {
            transport: async (params: any, success: any): Promise<any> => {
                await projectService.getSubProjects(this.dispatchProjectCode, params.data.filter, params.data.page)
                .then((data: any) => {
                    return success(data);
                });
            }
        };
    }

    public get getTrades(): any {
        return {
            transport: async (params: any, success: any): Promise<any> => {
                await tradesService.getTradesForDispatch(this.dispatch.id, params.data.filter, params.data.page || 1)
                .then((data: any) => {
                    return success(_.map(data, (x: any) => {
                        x.Id = x.Code;
                        return x;
                    }));
                });
            }
        };
    }

    public get lookupEquipment(): any {
        return {
            transport: async (params: any, success: any): Promise<any> => {
                await this.dispatchProjectTimeEntryService.GetSeperateTimeLinkedEquipmentDispatches(this.dispatch.id, params.data.filter, params.data.page)
                .then((data: any) => success(data));
            },
            mapResults: (item: any): any => {
                return { id: item.DispatchId, text: `${item.Code} - ${item.Description}`, data: item };
            }
        };
    }

    public get getActivity(): any {
        return {
            transport: async (params: any, success: any): Promise<any> => {
                if (!this.showSubProject) {
                    await projectService.getActivitiesForDispatchProject(this.dispatchProjectCode, params.data.filter, params.data.page || 1)
                        .then((data: any) => {
                            return success(data);
                        });
                } else if (this.subProject) {
                    await projectService.getActivitiesForProject(this.subProject.id, params.data.filter, params.data.page || 1)
                        .then((data: any) => {
                            return success(data);
                        });
                }
            },
            mapResults: (item: any): any => {
                return { id: item.Id, text: item.Id + " - " + item.Description, data: item };
            }
        };
    }

    public async setDefaultActivity(): Promise<void> {
        if (this.activityAvailable) {
            const activities = await this.getActivities();

            if (!this.dispatch.isEmployee && this.dispatch.DefaultEquipmentActivityCode) {
                this.activity = this.createDefaultMaSelectElement(this.dispatch.DefaultEquipmentActivityCode, activities);

            } else if (this.dispatch.isEmployee && this.dispatch.DefaultLaborActivityCode) {
                this.activity = this.createDefaultMaSelectElement(this.dispatch.DefaultLaborActivityCode, activities);
            } else {
                this.activity = null;
            }
        } else {
            this.activity = null;
        }
    }
    public async getActivities(): Promise<any[]> {
        let data = [];
        if (!this.showSubProject) {
            data = await projectService.getActivitiesForDispatchProject(this.dispatchProjectCode);

        } else if (this.subProject) {
            data = await projectService.getActivitiesForProject(this.subProject.id);
        }

        return data.map((item: any) => {
            const activity: any = {};

            activity.Id = item.Id;
            activity.Description = item.Description;

            return activity;
        });
    }

    public getDefaultQueryStringParameters(): any {
        const params: any = {};

        params.isProjectResponsible = this.isProjectResponsible;
        params.readonly = this.readonly;

        return params;
    }

    public createDefaultMaSelectElement(id: any, list: any): any {
        if (!id) { return null; }

        const result = list.find((item: any) => item.Id === id);
        if (!result) {
            return null;
        }

        return { id: result.Id, text: result.Id + " - " + result.Description };
    }

    public formatListToIdDesc(item: any): string {
        if (item.data) {
            return item.data.Id + " - " + item.data.Description;
        }
        return "";
    }

    public async resetBonuses(): Promise<void> {
        const success = await notificationHelper.showDialogYesNo(this.i18n.tr("WarningResetBonus"), "", false);
        if (success === true) {
            this.updateBonuses();
        }
    }

    public async deleteBonus(bonusId: any): Promise<void> {
        if (await notificationHelper.showDeleteConfirmation()) {
            await this.bonusService.DeleteBonus(bonusId);
            await this.loadData();
        }
    }

    public validateMidnightSwitch(isStart: boolean): boolean {
        const duration = dateHelper.getTimeSpanInHour(this.startTime, this.endTime);

        if (duration < 0) {
            if (isStart) {
                this.startTime = new Date(dateHelper.addDay(this.startTime, -1));
            } else {
                this.endTime = new Date(dateHelper.addDay(this.endTime, 1));
            }
            return true;
        }
        if (duration >= 24) {
            if (isStart) {
                this.startTime = new Date(dateHelper.addDay(this.startTime, 1));
            } else {
                this.endTime = new Date(dateHelper.addDay(this.endTime, -1));
            }
            return true;
        }
        return false;
    }

    public isEndTimeValid(): boolean {
        if (!this.endTime) {
            notificationHelper.showError(this.i18n.tr("err_EndTimeRequired"));
            return false;
        }
        if (moment(this.endTime).isSameOrBefore(moment(this.startTime))) {
            notificationHelper.showError(this.i18n.tr("err_EndTimeAfterStartTime"));
            return false;
        }
        return true;
    }
    public isStartTimeValid(): boolean {
        if (!this.startTime) {
            notificationHelper.showError(this.i18n.tr("err_StartTimeRequired"));
            return false;
        }
        return true;
    }

    public isEquipmentActivityValid(): boolean {
        if (!this.equipmentActivity && this.equipment) {
            notificationHelper.showError(this.i18n.tr("err_EquipmentActivityRequired"));
            return false;
        }
        return true;
    }

    public isEquipmentWorkTimeValid(): boolean {
        if (!this.equipmentWorkTime && this.equipment) {
            notificationHelper.showError(this.i18n.tr("err_EquipmentTimeSpentRequired"));
            return false;
        }
        return true;
    }

    public isValid(): boolean {
        return this.isEndTimeValid() && this.isStartTimeValid() && this.isEquipmentActivityValid() && this.isEquipmentWorkTimeValid();
    }

    public async save(forceSave: boolean): Promise<void> {
        if (!this.isValid()) {
            return;
        }

        const dispatchTemplateId = settingHelper.getSelectedDispatchModel();

        const dataToSend = [this.buildTimeEntryDto()];

        if (this.showEquipmentSection && this.equipment) {
            dataToSend.push(this.buildEmployeeEquipmentTimeEntry());
        }

        const result = await dispatchTimesheetService.setTimeEntries(dataToSend, forceSave, dispatchTemplateId, this.dispatchProjectCode, dateHelper.formatDateToSend(this.dispatchDate));

        if (!result.HasConflictsOnCurrentProject && !result.HasConflictsOnOtherProjects) {
            routerHelper.navigateBack();
        } else {
            const conflictsMessage = this.projectTimeEntryHelper.buildConflictsMessage(result.Entries);

            const success = await notificationHelper.showDialogYesNo(conflictsMessage, this.i18n.tr("War_ValuesEnteredInTheSameRange"), false);
            if (success === true) {
                this.save(true); //with force save
            }
        }
    }

    public durationChanged(newValue: number): any {
        if (this.startTime) {
            this.endTime = dateHelper.addHour(new Date(this.startTime), newValue ? newValue : 0);

            if (!this.timeEntryId || this.bonusListUpdatedFromInterface) {
                this.updateBonuses();
            } else {
                this.updateBonusesQtiesHours();
            }
        }
    }

    public startTimeChanged(value: any,  oldValue: any): any {
        if (!this.startTime || !this.endTime || !oldValue || value.toString() === oldValue.toString()) { return; }
        if (!this.validateMidnightSwitch(true)) {
            this.updateDuration();
        }
    }

    public endTimeChanged(value: any, oldValue: any): any {
        if (!this.startTime || !this.endTime || !oldValue || value.toString() === oldValue.toString()) { return; }
        if (!this.validateMidnightSwitch(false)) {
            this.updateDuration();
        }
    }

    public async subProjectChanged(newValue: any, oldValue: any): Promise<any> {
        if (this.flagCancelDialog || this.isLoading) {
            this.flagCancelDialog = false;
            return;
        }

        this.flagCancelDialog = true;

        if (this.subProject !== null) {
            const success = await notificationHelper.showDialogOkCancel(this.i18n.tr("WarningBonusWillBeModified"), "");
            if (success === true) {
                await this.setDefaultActivity();
                this.updateBonuses();
            } else {
                this.subProject = oldValue;
            }
        }
    }

    public async wageTypeChanged(): Promise<any> {
        if (!this.timeEntryId || this.bonusListUpdatedFromInterface) {
            this.updateBonuses();
        } else {
            this.updateBonusesQtiesHours();
        }
    }

    public async activityChanged(newValue: any, oldValue: any): Promise<any> {
        if (this.flagCancelDialog || this.isLoading) {
            this.flagCancelDialog = false;
            return;
        }
        if (this.applyDefaultBonus && this.dispatch.isEmployee && this.activity !== null) {
            const success = await notificationHelper.showDialogOkCancel(this.i18n.tr("WarningBonusWillBeModified"), "");
            if (success === true) {
                this.updateBonuses();
            } else {
                this.flagCancelDialog = true;
                this.activity = oldValue;
            }
        }
    }

    public async tradeChanged(newValue: any, oldValue: any): Promise<any> {
        if (this.flagCancelDialog || this.isLoading) {
            this.flagCancelDialog = false;
            return;
        }

        if (this.applyDefaultBonus && this.dispatch.isEmployee && this.trade !== null && !this.activityAvailable) {

            const success = await notificationHelper.showDialogOkCancel(this.i18n.tr("WarningBonusWillBeModified"), "");
            if (success === true) {
                this.flagCancelDialog = true;
                if (this.trade.data.DefaultActivityCode) {
                    this.activity = { id: this.trade.data.DefaultActivityCode };
                } else {
                    this.activity = null;
                }
                this.updateBonuses();
            } else {
                this.flagCancelDialog = true;
                this.trade = oldValue;
            }
        }
    }

    public async shiftChanged(value: any, oldValue: any): Promise<any> {
        if (this.flagCancelDialog || this.isLoading) {
            this.flagCancelDialog = false;
            return;
        }

        if (this.applyDefaultBonus && this.dispatch.isEmployee && this.activity !== null && this.shift !== null) {
                const success = await notificationHelper.showDialogOkCancel(this.i18n.tr("WarningBonusWillBeModified"), "");
                if (success === true) {
                    this.updateBonuses();
                } else {
                    this.flagCancelDialog = true;
                    this.shift = oldValue;
                }
        }
    }

    public async equipmentChanged(newValue: any): Promise<any> {
        this.equipmentActivity = null;
        if (this.equipment !== null) {
            const activities = await this.getActivities();
            const defaultActivity = activities.find((x: any) => x.Id === newValue.data.data.DefaultActivityId);
            if (defaultActivity) {
                this.equipmentActivity = { id: defaultActivity.Id, text: defaultActivity.Id + " - " + defaultActivity.Description };
            }
        }
    }
}
