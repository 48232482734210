import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { MaCardOptionsModel, MaCardLineModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import { ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { autoinject, computedFrom } from "aurelia-framework";
import { ValidationController, ValidationControllerFactory } from "aurelia-validation";
import { ApiHelper } from "helpers/api-helper";
import { RouterHelper } from "helpers/router-helper";
import { ValidationHelper } from "helpers/validation-helper";
import { QuotationItemMaterialBase } from "pages/services/quotations/item-material-base";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { SettingRepository } from "repositories/setting-repository";
import { ServiceCallNonBillingReasonService } from "services/service-call-non-billable-reason-service";
import { I18N } from "aurelia-i18n";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { ServiceCallQuotationPriceService } from "services/service-call-quotation-price-service";
import { CurrencyService } from "services/currency-service";
import templateService from "services/templateService";

export interface QuotationItemMaterialParameters {
    quotationId: number;
    type: ServiceCallQuotationItemType;
    itemId?: number;
}

@autoinject()
export class QuotationItemMaterial extends QuotationItemMaterialBase {
    public materialItemCards: MaCardOptionsModel[] = [];

    private cardIndex: number = 0;
    private type!: ServiceCallQuotationItemType;

    constructor(
        validationControllerFactory: ValidationControllerFactory,
        i18N: I18N,
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallNonBillingReasonService: ServiceCallNonBillingReasonService,
        validationHelper: ValidationHelper,
        validationController: ValidationController,
        apiHelper: ApiHelper,
        settingRepository: SettingRepository,
        dispatchTemplateService: DispatchTemplateService,
        serviceCallQuotationPriceService: ServiceCallQuotationPriceService,
        currencyService: CurrencyService,
        private readonly routerHelper: RouterHelper
    ) {
        super(validationControllerFactory, i18N, serviceCallQuotationService, serviceCallNonBillingReasonService, validationHelper, validationController, apiHelper, settingRepository, dispatchTemplateService, serviceCallQuotationPriceService, currencyService);
    }

    public async activate(params: QuotationItemMaterialParameters): Promise<void> {
        this.type = params.type;

        await this.initQuotationItem(params.quotationId, params.type, params.itemId);

        await this.initCatalogBaseUrl();
    }

    public async save(): Promise<void> {
        if (this.materialItemCards.length > 0) {
            const data: ServiceCallQuotationItemModel[] = this.materialItemCards.map((card: MaCardOptionsModel) => card.model);

            await this.serviceCallQuotationService.saveQuotationItems(data);

            this.routerHelper.navigateBack();
            return;
        }

        const isValid = await this.validate();

        if (!isValid) {
            return;
        }

        // Update linked items (only if the catalog item or the quantity was changed).
        if (this.quotationItem.Quantity !== this.originalQuantity || this.quotationItem.CatalogId !== this.selectedItem.id) {
            await this.updateLinkedItems();
        }

        this.quotationItem.CatalogId = this.selectedItem.id;

        await this.serviceCallQuotationService.saveQuotationItem(this.quotationItem);
        this.routerHelper.navigateBack();
    }

    public async add(): Promise<void> {
        if (!await this.validate()) {
            return;
        }

        if (this.quotationItem.Quantity !== this.originalQuantity || this.quotationItem.CatalogId !== this.selectedItem.id) {
            await this.updateLinkedItems();
        }

        this.quotationItem.CatalogId = this.selectedItem.id;

        this.materialItemCards.push(this.createCard(this.quotationItem));
        this.clearForm();
    }

    public deleteCard(cardIndex: number): void {
        this.materialItemCards = this.materialItemCards.filter((card: MaCardOptionsModel) => {
            return card.id !== cardIndex;
        });

    }

    public createCard(item: ServiceCallQuotationItemModel): MaCardOptionsModel {
        this.cardIndex++;

        const card: MaCardOptionsModel = new MaCardOptionsModel( {
            id: this.cardIndex,
            model: item,
            displayLines: [
                new MaCardLineModel({ display: `${item.CatalogId === "!" ? item.CatalogId + item.OutCatalogCode : item.CatalogId} - ${item.Description}`, class: "text-primary font-weight-bold"}),
                new MaCardLineModel({ display: `${this.i18n.tr("Quantity")}: ${item.Quantity}`, class: "font-weight-bold"}),
            ],
            actionItems: [new MaCardActionModel({id: "delete", icon: "fa fa-trash text-danger", action: this.deleteCard.bind(this, this.cardIndex) })],
        });

        return card;
    }

    private async initQuotationItem(quotationId: number, type: ServiceCallQuotationItemType, itemId: number | undefined): Promise<void> {
        await this.initQuotation(quotationId);

        if (itemId !== null && itemId !== undefined) {
            this.quotationItem = await this.serviceCallQuotationService.getQuotationItem(itemId);
            this.originalQuantity = this.quotationItem.Quantity;
        } else {
            this.quotationItem = this.serviceCallQuotationService.createNewQuotationItem(quotationId, type, this.quotation.ProfitMargin);
        }

        this.initValidation();
        this.initSelectedItem(this.quotationItem);
        this.calculationInProgress = true;
        this.unitCost = this.quotationItem.UnitCost;
        this.calculationInProgress = false;
    }

    private clearForm(): void {
        this.quotationItem = this.serviceCallQuotationService.createNewQuotationItem(this.quotation.Id, this.type, this.quotation.ProfitMargin);
        this.selectedItem = null;
    }

}
