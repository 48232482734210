import { Viewbag } from "helpers/view-bag";
import { default as routerHelper } from "helpers/routerHelper";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { AdditionalFieldsHelper } from "helpers/additional-fields-helper";
import enumHelper from "helpers/enumHelper";
import notificationHelper from "helpers/notificationHelper";
import clientService from "services/clientService";
import workOrderService from "services/workOrderService";
import UserAccessService from "services/user-access-service";

@autoinject
export class FieldServiceDetails {

    public btnLinks: any = [];

    public client: any;
    public detail: any;
    public completeAvailable: boolean = false;
    public signatureAvailable: boolean = false;
    public readonly: boolean = false;
    public editModes: any = enumHelper.fieldServiceEditMode();

    public lblSubheaderTitle: string = "";
    public lblAddress: string = "";
    public workOrderId: string = "";

    public isReadonly: boolean = true;

    constructor(private readonly i18n: I18N, private readonly additionalFieldsHelper: AdditionalFieldsHelper, public viewbag: Viewbag, private readonly userAccessService: UserAccessService) {
    }

    public async activate(params: any): Promise<any> {
        await this.loadData(params.workOrderId);
    }

    public setLabels(): void {
        this.lblSubheaderTitle = this.i18n.tr("WorkOrder") + " - " + this.detail.Id;
        this.lblAddress = this.detail.LocationAddress + ", " + this.detail.LocationCity + (this.detail.LocationProvince ? " (" + this.detail.LocationProvince + ") " : " ") + this.detail.LocationPostalCode;
    }

    public setButtonLinks(): void {
        this.btnLinks = [
            {
                disabled: false,
                disabledAdd: false,
                display: true,
                displayAdd: false,
                displayChevron: true,
                displayCount: true,
                count: this.detail.ContactCount,
                linkAdd: "",
                linkMain: this.genUrl("contact"),
                text: this.i18n.tr("Contacts")
            },
            {
                disabled: false,
                disabledAdd: false,
                display: true,
                displayAdd: !this.readonly,
                displayChevron: this.readonly,
                displayCount: true,
                count: this.detail.MaterialCount,
                linkAdd: this.genUrl("material/edit/false/-1"),
                linkMain: this.genUrl("material"),
                text: this.i18n.tr("MaterialAndBillingItem")
            },
            {
                disabled: false,
                disabledAdd: false,
                display: true,
                displayAdd: !this.readonly,
                displayChevron: this.readonly,
                displayCount: true,
                count: this.detail.MaterialRequisitionCount,
                linkAdd: this.genUrl("materialRequisition/edit"),
                linkMain: this.genUrl("materialRequisition"),
                text: this.i18n.tr("MaterialRequisition")
            },
            {
                id: "workTasks",
                disabled: false,
                disabledAdd: true,
                display: true, //TODO DONT SHOW IF MAINTENANCE
                displayAdd: false,
                displayChevron: this.isReadonly,
                displayCount: true,
                count: this.detail.WorkTasksCount,
                linkAdd: this.genUrl("workTask"),
                linkMain: this.genUrl("workTask"),
                text: this.i18n.tr("WorkTask")
            },
            {
                id: "orders",
                disabled: false,
                disabledAdd: false,
                display: this.userAccessService.isOptionEnabledSync(enumHelper.userOptions.FIELDSERVICESTOCKORDERFROMCATALOG),
                displayAdd: !this.isReadonly,
                displayChevron: this.isReadonly,
                displayCount: true,
                count: this.detail.OrdersCount,
                linkAdd: this.genUrl("materialOrder/edit"),
                linkMain: this.genUrl("materialOrder"),
                text: this.i18n.tr("Orders")
            },
            {
                disabled: false,
                disabledAdd: false,
                display: true,
                displayAdd: !this.readonly,
                displayChevron: this.readonly,
                displayCount: true,
                count: this.detail.RecommendationCount,
                linkAdd: this.genUrl("recommendation/edit"),
                linkMain: this.genUrl("recommendation"),
                text: this.i18n.tr("Recommendations")
            },
            {
                disabled: false,
                disabledAdd: false,
                display: true,
                displayAdd: true,
                displayChevron: false,
                displayCount: false,
                count: 0,
                linkAdd: this.genUrl("document/add"),
                linkMain: this.genUrl("document"),
                text: this.i18n.tr("Pictures/Documents")
            },
            {
                disabled: false,
                disabledAdd: false,
                display: true,
                displayAdd: false,
                displayChevron: true,
                displayCount: false,
                count: 0,
                linkAdd: "",
                linkMain: this.genUrl("address"),
                text: this.i18n.tr("WorkAddress")
            },
            {
                disabled: false,
                disabledAdd: false,
                display: true,
                displayAdd: false,
                displayChevron: true,
                displayCount: false,
                count: 0,
                linkAdd: this.genUrl("addidditionalFields"),
                linkMain: this.genUrl("additionalFields"),
                text: this.i18n.tr("AdditionalFields")
            },
            {
                disabled: false,
                disabledAdd: false,
                display: this.detail.WorkOrderTypeId !== "",
                displayAdd: false,
                displayChevron: true,
                displayCount: false,
                count: 0,
                linkAdd: this.genUrl("addidditionalFieldsType"),
                linkMain: this.genUrl("additionalFieldsType"),
                text: `${this.i18n.tr("AdditionalFields")} (${this.detail.WorkOrderTypeId})`
            },
            {
                disabled: false,
                disabledAdd: false,
                display: true,
                displayAdd: false,
                displayChevron: true,
                displayCount: false,
                count: 0,
                linkAdd: "",
                linkMain: this.genUrl("summary"),
                text: this.i18n.tr("WorkOrderSummary")
            }
        ];
    }

    public setViewModelProperties(): any {
        this.signatureAvailable = this.client === null || this.client.MobilitySignatureRequiredness !== 2;
        this.completeAvailable = this.client && this.client.MobilitySignatureRequiredness !== 1;

        this.readonly = this.detail.IsReadOnly;

        this.setLabels();
        this.setButtonLinks();
    }

    public async loadData(workOrderId: string): Promise<void> {
        this.workOrderId = workOrderId;
        this.detail = await workOrderService.get(workOrderId);
        this.client = await clientService.getClientInfoForWorkOrder(workOrderId);

        this.setViewModelProperties();
    }

    public validateReadyToComplete(): any {
        if (!this.detail.ReadyToComplete.IsReadyToComplete) {
            let message = "";
            if (!this.detail.ReadyToComplete.AllDispatchesTimeEntryIsEntered) {
                message = this.i18n.tr("msg_FieldService_EnterTimeForXEmployees").replace("{0}", this.detail.PresentDispatchesCount);
                notificationHelper.showWarning(message, undefined, { timeOut: 0 });
            }

            if (!this.detail.ReadyToComplete.AllRequiredAdditionalFieldsIsEntered) {
                message = this.additionalFieldsHelper.getRequiredRessourceBySourceType(AdditionalFieldsSourceType.FieldService);
                notificationHelper.showWarning(message, undefined, { timeOut: 0 });
            }
        }

        return this.detail.ReadyToComplete.IsReadyToComplete;
    }

    public async complete(): Promise<any> {
        if (this.client === null) {
            return;
        }

        if (this.validateReadyToComplete()) {
            if (await notificationHelper.showConfirmation(this.i18n.tr("msg_CompleteWorkOrderConfirmationText"))) {

                await workOrderService.complete(this.detail.Id);
                //Never call printForm in fieldService (233426)
                //workOrderService.printForm(self.detail.Id, '');
                routerHelper.navigate(routerHelper.navigateTo("FieldService"));
            }
        }
    }

    public genUrl(routeName: string): string {
        let url = routerHelper.getRelativeUrl(routeName);
        const queryString = {
            readonly: this.readonly,
            isCompleted: this.detail.IsCompleted,
            workOrderId: this.workOrderId,
            workOrderType: this.detail.WorkOrderTypeId,
            projectCode: this.detail.ProjectCode,
            dispatchProjectCode: this.detail.ProjectCode
        };

        url += routerHelper.addQuerystring(queryString);

        return  url;
    }

    public genUrlAddHours(routeName: string): string {
        return routerHelper.getRelativeUrl(routeName) + routerHelper.addQuerystring({
            readonly: this.readonly,
            selectedEmployees: JSON.stringify([]),
            multi: true,
            isEmployee: true
        });
    }

    public genUrlEditMode(routeName: string, editMode: string): string {
        return routerHelper.getRelativeUrl(routeName) + routerHelper.addQuerystring({ readonly: editMode === enumHelper.fieldServiceEditMode().SUB ? false : this.readonly, editMode: editMode });
    }

    public navigateToSummarySignature(): void {
        if (this.validateReadyToComplete()) {
            this.viewbag.value = { isFromFieldServiceSignature: true };
            routerHelper.navigate(this.genUrl("summary"));
        }
    }

}
