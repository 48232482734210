import { default as signPad } from "signature_pad"
import "widgets/pageBottom";

define([
    "jquery",
    "knockout",
    "core/resx",
    "services/serviceService",
    "services/dispatchService",
    "helpers/dateHelper",
    "helpers/notificationHelper",
    "helpers/routerHelper",
    "knockout.validation",
    "select2"
], function (jQuery, ko, resx, service, dispatch, hlpDate, notifier, routerHelper) {
    "use strict";

    var _isCanvasEmpty = true;
    var _timerId;

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            self.callId = "";
            self.resx = resx;
            self.actions = {};
            self.txtSignature = "";
            self.signaturePad = undefined;
            self.showCanvas = ko.observable(false);
            self.showRotateMessage = ko.observable(false);
            self.initializingCanvas = false;
        }

        function onResize() {
            window.clearTimeout(_timerId);

            if (self.initializingCanvas) {
                self.initializingCanvas = false;
                routerHelper.hideLoading();
            }

            // Don't resize the canvas anymore once the user starts writing.
            if (!_isCanvasEmpty) {
                return;
            }

            // Wait a specific delay before finishing the canvas initialization due to problems with the resize event on Safari.
            self.initializingCanvas = true;
            routerHelper.showLoading();
            _timerId = window.setTimeout(() => initCanvas(), 1250);
        }

        function getCanvasSizeFromWindowSize() {
            return { width: window.innerWidth, height: window.innerHeight - 95 }; // TODO ML: 95?? Devrait être calculé?
        }

        function isOrientationHorizontal() {
            const targetCanvasSize = getCanvasSizeFromWindowSize();
            return targetCanvasSize.width > targetCanvasSize.height;
        }

        function initCanvas() {
            if (self.initializingCanvas) {
                self.initializingCanvas = false;
                routerHelper.hideLoading();
            }

            // Hide the canvas and show the "rotate" message if needed.
            const mustRotate = !isOrientationHorizontal();
            self.showRotateMessage(mustRotate);
            self.showCanvas(!mustRotate);

            if (!mustRotate) {
                const targetCanvasSize = getCanvasSizeFromWindowSize();
                setCanvasSize(targetCanvasSize.width, targetCanvasSize.height);
            }
        }

        function setCanvasSize(width, height) {
            jQuery("#sccanvas").attr({
                height: height,
                width: width
            });
        }

        function initSignaturePadResize() {
            //window.addEventListener('orientationchange', onResize, false);
            window.addEventListener('resize', onResize, false);
            onResize('');
        }

        function disposeSignaturePadResize() {
            //window.removeEventListener('orientationchange', onResize, false);
            window.removeEventListener('resize', onResize, false);
        }

        function getSignatureData() {
            var signatureData = self.signaturePad.toDataURL();

            return {
                Comment: self.txtSignature.replace(" ", " "),
                Email: self.txtEmail ? self.txtEmail.replace(" ", " ") : "",
                FileData: signatureData
            };
        }

        function setCanvasNotEmpty() {
            _isCanvasEmpty = false;
        }

        function hideKeyboard() {
            // hack to hide keyboard prior to showing popup... ios bug with popup not being full screen
            jQuery('#ttlHeader').focus();
        }

        ctor.prototype.activate = function (widgetSettings) {
            self.actions = widgetSettings.actions;
            self.txtSignature = widgetSettings.txtSignature;
            self.txtEmail = widgetSettings.txtEmail;
            return true;
        };

        ctor.prototype.compositionCompleteCallback = function () {
            hideKeyboard();

            var canvas = jQuery("#sccanvas");
            self.signaturePad = new signPad(canvas.get(0));
            _isCanvasEmpty = true;

            initSignaturePadResize();

            jQuery(self.signaturePad._canvas).on("click", setCanvasNotEmpty);
            jQuery(self.signaturePad._canvas).on("touchstart", setCanvasNotEmpty);
        };

        ctor.prototype.clear = function () {
            notifier.showDialogYesNo(resx.localize('DoYouWantToClear'))
                .done(function (success) {
                    if (success) {
                        self.signaturePad.clear();
                        _isCanvasEmpty = true;
                    }
                });
        };

        ctor.prototype.save = function () {
            if (_isCanvasEmpty) {
                notifier.showWarning(resx.localize('err_SignatureRequired'));
                return;
            }
            routerHelper.showLoading();
            self.actions.sign(getSignatureData());
            self.actions.close()
                .done(function () {
                    self.actions.printForm();
                    routerHelper.navigateBack();
                }).always(routerHelper.hideLoading);
        };

        return ctor;
    })();

    return viewModel;
});
