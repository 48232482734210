import { ProductionService } from "services/production-service";
import notificationHelper from "helpers/notificationHelper";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import projectService from "services/projectService";
import routerHelper from "helpers/routerHelper";
import { ProductionModel } from "api/models/company/project/dispatch/production-model";

@autoinject
export class DailyEntryProductionEdit {

    public dispatchProjectCode: string = "";
    public dispatchDate: Date = new Date();
    public line: any;
    public productionItemId: number = 0;
    public isProjectResponsible: boolean = false;
    public readonly: boolean = true;
    public activity: any;
    public quantity: number = 1;

    constructor(private readonly i18n: I18N, private readonly productionService: ProductionService) {}

    public async activate(params: any): Promise<any> {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.id, params.line, params.q);
        await this.loadData();
    }

    public async loadData(): Promise<any> {
        if (this.productionItemId && this.line) {
            this.initialiseProductionsItem((await this.productionService.GetProductionItem(this.dispatchProjectCode, this.dispatchDate, this.productionItemId, this.line))!);
        }
    }

    public get getActivities(): any {
        return {
            transport: async (params: any, success: any): Promise<any> => {
                await projectService.getActivitiesForDispatchProject(this.dispatchProjectCode, params.data.filter, params.data.page || 1).then((data: any) => {
                    return success(data);
                });
            },
            mapResults: (item: any): any => {
                return { id: item.Id, text: `${item.Id} - ${item.Description}`, productionUnit: item.ProductionUnit };
            }
        };
    }

    public bindViewModel(dispatchProjectCode: string, dispatchDate: Date, id: number, line: number, queryString: string): any {
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);
        this.readonly = queryStringHelper.parseReadonly(queryString);

        this.dispatchDate = dispatchDate;
        this.dispatchProjectCode = dispatchProjectCode;
        this.line = line;
        this.productionItemId = id;
    }

    public buildProductionDto(): any {
        const data: any = {};

        if (this.productionItemId) {
            data.Id = this.productionItemId;
            data.Line = this.line;
        }

        data.ActivityCode = this.activity.id;
        data.ActivityDescription = this.activity.text;
        data.Quantity = this.quantity;

        return data;
    }

    public initialiseProductionsItem(data: ProductionModel): any {
        this.readonly = false;

        this.activity = { id: data.ActivityCode, text: `${data.ActivityCode} - ${data.ActivityDescription}`, data: { productionUnit: data.Unit } };
        this.quantity = data.Quantity;
    }

    public isValid(): boolean {
        if (!this.isActivityValid() || !this.isQuantityValid()) {
             return false;
        }

        return true;
    }

    public isActivityValid(): boolean {
        if (!this.activity) {
            notificationHelper.showError(this.i18n.tr("err_ActivityRequired"));
            return false;
        }
        return true;
    }

    public isQuantityValid(): boolean {
        if (!this.quantity) {
            notificationHelper.showError(this.i18n.tr("err_QuantityRequired"));
            return false;
        }
        if (this.quantity <= 0) {
            notificationHelper.showError(this.i18n.tr("err_MinQuantity"));
            return false;
        }
        return true;
    }

    public async save(): Promise<void> {
        if (this.isValid()) {
                await this.productionService.SaveProduction(this.dispatchProjectCode, this.dispatchDate, this.buildProductionDto());
                routerHelper.navigateBack();
        }
    }
}
