import { ProductionProxy } from "api/proxies/production-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import { ProductionModel } from "api/models/company/project/dispatch/production-model";
import { ProductionCollectionModel } from "api/models/company/project/dispatch/production-collection-model";

@autoinject()
export class ProductionService {

    constructor(private readonly productionProxy: ProductionProxy) {
    }

    public async GetProductionItem(projectDispatchCode: string | null, dispatchDate: Date, id: number, line: number, requestConfig?: HttpClientRequestConfig): Promise<ProductionModel | null> {
        return await this.productionProxy.GetProductionItem(projectDispatchCode, dispatchDate, id, line, requestConfig);
    }

    public async SaveProduction(dispatchProjectCode: string, dispatchDate: Date, model: any): Promise<ProductionCollectionModel | null> {
        return await this.productionProxy.SaveProduction(dispatchProjectCode, dispatchDate, model);
    }

    public async GetProduction(dispatchProjectCode: string, dispatchDate: Date): Promise<ProductionCollectionModel | null> {
        return await this.productionProxy.GetProduction(dispatchProjectCode, dispatchDate);
    }

    public async DeleteProduction(projectDispatchCode: string | null, dispatchDate: Date, id: number, line: number, requestConfig?: HttpClientRequestConfig): Promise<ProductionCollectionModel | null> {
        return await this.productionProxy.DeleteProduction(projectDispatchCode, dispatchDate, id, line, requestConfig);
    }

}
