import serviceService from "services/serviceService";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import queryStringHelper from "helpers/queryStringHelper";

@autoinject
export class ServiceMaterial {

    public dispatchId: number = 0;
    public readonly: boolean = false;
    public serviceType: string = "";
    public fromHistory: boolean = false;
    public actions: any;

    constructor(private readonly i18n: I18N) {

    }

    public activate(params: any): void {
        this.bindViewModel(params.serviceType, params.dispatchId, params.q);
    }

    public bindViewModel(serviceType: string, dispatchId: number, querystring: string): void {
        this.dispatchId = dispatchId;
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.serviceType = serviceType;
        this.fromHistory = queryStringHelper.parse(querystring, "fromHistory") === "true";
        this.fromHistory = !!this.fromHistory;

        this.actions = {
            deleteMaterial: serviceService.deleteMaterial.bind(serviceService, this.serviceType, this.dispatchId),
            getMaterials: serviceService.getMaterials.bind(serviceService, this.serviceType, this.dispatchId, this.fromHistory),
            getReserved: serviceService.getIsReservedMaterials.bind(serviceService, this.serviceType, this.dispatchId),
            setReservedMaterials: serviceService.setReservedMaterials.bind(serviceService, this.serviceType, this.dispatchId)
        };
    }
}
