import { ReceiptOfGoodsProxy } from "api/proxies/receipt-of-goods-proxy";
import { ReceiptOfGoodsItemModel } from "api/models/company/receipt-of-goods-item-model";
import { ReceiptOfGoodsModel } from "api/models/company/receipt-of-goods-model";
import { ReceiptOfGoodsResponseModel } from "api/models/company/receipt-of-goods-response-model";
import { PagingInfo } from "api/paging-info";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";
import notificationHelper from "helpers/notificationHelper";
import { I18N } from "aurelia-i18n";
import { ReceiptOfGoodsQtyValidation } from "api/enums/receipt-of-goods-qty-validation";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";
import { CatalogItemModel } from "api/models/company/catalog/catalog-item-model";
import { ReceiptOfGoodsSerialNoModel } from "api/models/company/receipt-of-goods-serial-no-model";
import { ManageReceiptOfGoodsItemParameters } from "pages/receipt-of-goods/add-item";
import { PurchaseOrderSubContractType } from "api/enums/purchase-order-sub-contract-type";

@autoinject()
export class ReceiptOfGoodsService {
    private oldItem: any|null = null;

    constructor(private readonly receiptOfGoodsProxy: ReceiptOfGoodsProxy,
                private readonly routeRepository: RouteRepository,
                private readonly i18n: I18N) {
    }

    public async GetReceiptsOfGoods(onlyClose: boolean, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsModel[] | null> {
        return this.receiptOfGoodsProxy.GetReceiptsOfGoods(onlyClose, filter, pagingInfo, requestConfig);
    }

    public async GetReceiptsOfGoodsForOrder(onlyClose: boolean, purchaseOrderId: string | null, extension: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsModel[] | null> {
        return this.receiptOfGoodsProxy.GetReceiptsOfGoodsForOrder(onlyClose, purchaseOrderId, extension, filter, pagingInfo, requestConfig);
    }

    public async GetReceiptsOfGoodsHeader(receiptId: number, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsModel | null> {
      return this.receiptOfGoodsProxy.GetReceiptsOfGoodsHeader(receiptId, requestConfig);
    }

    public async CreateReceiptOfGoodsFromPurchaseOrder(purchaseOrderId: string | null, extension: string | null | undefined, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsResponseModel | null> {

        return this.receiptOfGoodsProxy.CreateReceiptOfGoodsFromPurchaseOrder(purchaseOrderId, extension, requestConfig);
    }

    public async Get(receiptId: number, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsResponseModel | null> {
        return this.receiptOfGoodsProxy.Get(receiptId, requestConfig);
    }

    public async DeleteReceiptOfGoods(receiptId: number, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
       return this.receiptOfGoodsProxy.DeleteReceiptOfGoods(receiptId, requestConfig);
    }

    public async SaveReceiptOfGoods(receiptOfGoods: ReceiptOfGoodsModel | null, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsResponseModel | null> {
       return this.receiptOfGoodsProxy.SaveReceiptOfGoods(receiptOfGoods, requestConfig);
    }

    public async SaveReceiptOfGoodsDeliveryNo(receiptId: number, deliveryNo: string | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
       return this.receiptOfGoodsProxy.SaveReceiptOfGoodsDeliveryNo(receiptId, deliveryNo, requestConfig);
    }

    public async GetReceiptOfGoodsDetail(receiptId: number, lineNo: number, productCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsItemModel | null> {
        return this.receiptOfGoodsProxy.GetReceiptOfGoodsDetail(receiptId, lineNo, productCode, requestConfig);
    }

    public async SaveReceiptOfGoodsDetail(item: ReceiptOfGoodsItemModel | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
      return this.receiptOfGoodsProxy.SaveReceiptOfGoodsDetail(item, requestConfig);
    }

    public async createReceipt(orderNumber: string | null, extension: string | null): Promise<ReceiptOfGoodsModel | null> {
        extension = extension !== "" ? extension : null;
        const order = await this.receiptOfGoodsProxy.CreateReceiptOfGoodsFromPurchaseOrder(orderNumber, extension);

        if (order!.WarningCode > 0) {
          notificationHelper.showWarning(this.i18n.tr("ApiError." + order!.WarningCode), "", { timeout: 0});
        }

        return order!.ReceiptOfGoods;
      }

      public async save(order: ReceiptOfGoodsModel, item?: ReceiptOfGoodsItemModel, isInventory?: boolean | undefined, validationMode?: string | null): Promise<void> {
        if (item !== undefined && isInventory !== undefined) {

          this.convertToSupplier(item, isInventory);

          if (!this.validate(order, item, validationMode)) { return; }
        }

        await this.receiptOfGoodsProxy.SaveReceiptOfGoods(order);
        this.oldItem = item;
      }

      public async complete(order: ReceiptOfGoodsModel, validationMode?: string | null): Promise<void> {
        const hasErrors = order.Items!.some((item: ReceiptOfGoodsItemModel) => {
            return !this.validate(order, item, validationMode, false);
        });

        if (hasErrors) { return; }

        const dialogMessage = this.i18n.tr("ReceiptOfGoodsConfirmComplete");

        notificationHelper.showDialogYesNo(dialogMessage).then(
            async (success: boolean): Promise<any> => {
                if (success) {
                  await this.receiptOfGoodsProxy.Complete(order.Id);
                  routerHelper.navigateToRoute(this.routeRepository.routes.ReceiptOfGoods.name);
                }
            }
        );
      }

      public convertToSupplier(item: ReceiptOfGoodsItemModel, isInventory: boolean): void {
        if (!!isInventory) {
            item.SupplierQtyReceived = item.InventoryQtyReceived * (item.SupplierFactor /  item.InventoryFactor);
        } else {
            item.InventoryQtyReceived = item.SupplierQtyReceived * (item.InventoryFactor / item.SupplierFactor);
        }
      }

      public async getOrder(receiptId: number): Promise<ReceiptOfGoodsResponseModel | null> {
        const order = await this.receiptOfGoodsProxy.Get(receiptId);

        if (order!.WarningCode > 0) {
          notificationHelper.showWarning(this.i18n.tr(order!.WarningCode), "", { timeout: 0});
        }

        return order;
      }

    public async GetNewReceiptOfGoodsDetail(receiptId: number, productCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<ReceiptOfGoodsItemModel | null> {
        return await this.receiptOfGoodsProxy.GetNewReceiptOfGoodsDetail(receiptId, productCode, requestConfig);
    }

    public async DeleteReceiptOfGoodsDetail(receiptId: number, lineNo: number, productCode: string | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        return await this.receiptOfGoodsProxy.DeleteReceiptOfGoodsDetail(receiptId, lineNo, productCode, requestConfig);
    }

    private validate(order: ReceiptOfGoodsModel, item: ReceiptOfGoodsItemModel, validationMode?: string | null, replaceValues: boolean = true): boolean {
        if (!validationMode || validationMode === ReceiptOfGoodsQtyValidation.Ignore || order.SubContractType === PurchaseOrderSubContractType.Open) { return true; }
        if (!item.AddedByUser) {
            if (item.InventoryQtyReceived > item.InventoryQtyToReceive ||
                item.SupplierQtyReceived > item.SupplierQtyToReceive) {

                this.showWarning(validationMode);

                if (validationMode === ReceiptOfGoodsQtyValidation.Block) {
                    if (!!replaceValues) {
                        this.replaceValues(item);
                    }

                    return false;
                }
            }
        }

        return true;
      }

      private replaceValues(item: ReceiptOfGoodsItemModel): void {
        item.InventoryQtyReceived = item.InventoryQtyToReceive;
        item.SupplierQtyReceived = item.SupplierQtyToReceive;
      }

      private showWarning(validationMode: string | null): void {
        let messageKey: string;
        switch (validationMode) {
          case ReceiptOfGoodsQtyValidation.Block:
            messageKey = "ReceiptOfGoodsInvalidFieldValidate";
            break;
          case ReceiptOfGoodsQtyValidation.Validate:
            messageKey = "ReceiptOfGoodsInvalidFieldBlocked";
            break;
          default:
            return;
        }

        notificationHelper.showWarning(this.i18n.tr(messageKey), "", { timeOut: 0});
      }
}
