import { TradeModel } from "api/models/company/trade-model";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { DailyEntryLinkEquipmentService } from "services/daily-entry-link-equipment-service";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";
import dispatchTimesheetService from "services/dispatchTimesheetService";
import dailyEntryService from "services/dailyEntryService";
import _ from "underscore";
import labelHelper from "helpers/labelHelper";
import dateHelper from "helpers/dateHelper";
import moment from "moment";
import dispatchService from "services/dispatchService";
import templateService from "services/templateService";
import notificationHelper from "helpers/notificationHelper";
import { BonusService } from "services/bonus-service";
import tradesService from "services/tradesService";

@autoinject
export class DailyEntryTimeEntryEmployee {

    public PAUSE_ACTIVITY_CODE: string = "PAUSE";

    public bonuses: any[] = [];
    public dispatchProjectCode: any;
    public dispatchDate: any;
    public dispatchId: any;
    public dispatch: any;
    public empFullName: any;
    public empId: any;
    public equipmentCode: any;
    public equipmentDescription: any;
    public hasLeft: any;
    public hasLeftCssClass: any;
    public inactive: any;
    public inactiveCssClass: any;
    public isProjectResponsible: boolean = false;
    public linkedEquipmentCode: any;
    public linkedEquipmentDescription: any;
    public linkedEquipments: any;
    public readonly: any = true;
    public requiredTomorrow: any;
    public requiredTomorrowCssClass: any;
    public timeEntries: any = [];
    public trade: any;
    public tradeCode: any;
    public tradeDescription: any;
    public dispatchTemplateID: string = "";
    public defaultEquipmentActivityCode: string = "";
    public defaultLaborActivityCode: string = "";
    public dailyEntryAllLinkedEquipments: any = [];

    public get isEmployee(): boolean {
        return this.empId !== 0;
    }

    public get hasEquipment(): boolean {
        return !!this.linkedEquipmentCode;
    }

    public get tradeText(): string {
        return this.tradeCode ? `${this.tradeCode} - ${this.tradeDescription}` : this.i18n.tr("SelectATrade");
    }

    constructor(private readonly i18n: I18N, private readonly dailyEntryLinkEquipmentService: DailyEntryLinkEquipmentService, private readonly bonusService: BonusService) {

    }

    public async activate(params: any): Promise<any> {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.dispatchId, params.q);
        await this.loadData();
    }

    public bindViewModel(dispatchProjectCode: string, dispatchDate: any, dispatchId: number, queryString: string): any {
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

        this.dispatchTemplateID = routerHelper.getQuerystring(queryString).DispatchTemplateID;
        this.dispatchProjectCode = dispatchProjectCode;
        this.dispatchDate = dispatchDate;
        this.dispatchId = dispatchId;
    }

    public async loadData(): Promise<any> {
        const timeSheets = await dispatchTimesheetService.getByDispach(this.dispatchId);
        this.dispatch = await dailyEntryService.get(this.dispatchProjectCode, this.dispatchDate, this.getTimeEntryMode(), this.getInChargeRequireIndicateWhenEmployeeLeftWorksite(), this.getAllowEquipmentTimeEntryForSimpleUser());

        this.initialize(timeSheets, this.dispatch);

        if (this.isEmployee) {
            this.dailyEntryAllLinkedEquipments = await dispatchTimesheetService.getAllDailyEntryLinkedEquipment(this.dispatchId);
        }
    }

    public getDefaultQueryStringParameters(): any {
        return {
            isProjectResponsible: this.isProjectResponsible,
            readonly: this.readonly
        };
    }

    public initialize(data: any, dispatch: any): any {
        this.readonly = dispatch.IsReadOnly;

        this.empId = data.EmployeeNumber;
        this.empFullName = data.EmployeeFirstName + " " + data.EmployeeLastName;
        this.equipmentCode = data.EquipmentCode;
        this.equipmentDescription = data.EquipmentDescription;

        this.tradeCode = data.TradeCode;
        this.tradeDescription = data.TradeDescription;

        this.linkedEquipmentCode = data.LinkedEquipmentCode;
        this.linkedEquipmentDescription = data.LinkedEquipmentDescription;
        this.linkedEquipments = data.LinkedEquipments;

        this.loadTimeEntries(data.TimeEntries);

        this.bonuses = data.Bonuses;

        this.hasLeft = data.HasLeft;
        this.inactive = data.IsInactive;
        this.requiredTomorrow = data.RequiredTomorrow;

        this.defaultEquipmentActivityCode = data.DefaultEquipmentActivityCode;
        this.defaultLaborActivityCode = data.DefaultLaborActivityCode;
    }

    public loadTimeEntries(timeEntries: any): any {
        this.timeEntries = _.map(timeEntries, (timeEntry: any) => {
            timeEntry.wageText = labelHelper.getWageTypeLabel(timeEntry.WageType);

            if (timeEntry.EndTime) {
                timeEntry.formattedTime = `${dateHelper.getTime(timeEntry.StartDateTime)} - ${dateHelper.getTime(timeEntry.EndDateTime)}`;
                timeEntry.hoursWorked = dateHelper.getTimeSpan(timeEntry.StartDateTime, timeEntry.EndDateTime);
            } else {
                timeEntry.formattedTime = `${dateHelper.getTime(timeEntry.StartDateTime)} - ...`;

                const diff = moment(dateHelper.getNow()).diff(moment(timeEntry.StartDateTime));
                timeEntry.hoursWorked = diff > 0 ? dateHelper.getTimeSpan(timeEntry.StartDateTime, dateHelper.getNow()) : "0:00";
            }

            return timeEntry;
        });
    }

    public getTimeEntryMode(): string {
        return templateService.getCurrentTemplateConfigs().ProjectTimeEntryMode;
    }

    public getInChargeRequireIndicateWhenEmployeeLeftWorksite(): boolean {
        return templateService.getCurrentTemplateConfigs().InChargeRequireIndicateWhenEmployeeLeftWorksite;
    }

    public getAllowEquipmentTimeEntryForSimpleUser(): boolean {
        return templateService.getCurrentTemplateConfigs().AllowEquipmentTimeEntry;
    }

    public getAddActivityUrl(item: any): string {
        let id: any;
        let name = this.isEmployee ? this.empFullName : this.equipmentDescription;
        if (item) {
            id = item.TimeEntryId;
        }
        if (item && item.EquipmentDescription) {
            name = item.EquipmentDescription;
        }

        const params = this.getDefaultQueryStringParameters();
        params.dispatchIds = JSON.stringify([{
            id: this.dispatchId,
            isEmployee: this.isEmployee,
            DispatchTemplateID: this.dispatchTemplateID,
            DefaultEquipmentActivityCode: this.defaultEquipmentActivityCode,
            DefaultLaborActivityCode: this.defaultLaborActivityCode,
            name: name
        }]);

        const queryString = routerHelper.addQuerystring(params);
        const url = id ? routerHelper.navigateTo("Project_Detail_Daily_Entry_TimeEntry_Edit", this.dispatchProjectCode, this.dispatchDate, id)
            : routerHelper.navigateTo("Project_Detail_Daily_Entry_TimeEntry_Edit", this.dispatchProjectCode, this.dispatchDate);
        return url + queryString;
    }

    public getAddBonusUrl(id: string): string {
        const params = this.getDefaultQueryStringParameters();
        params.dispatchIds = [this.dispatchId];
        params.name = this.isEmployee ? this.empFullName : this.equipmentDescription;

        const queryString = routerHelper.addQuerystring(params);
        const url = id
            ? routerHelper.navigateTo("Project_Detail_Daily_Entry_TimeEntry_Bonus", this.dispatchProjectCode, this.dispatchDate, id)
            : routerHelper.navigateTo("Project_Detail_Daily_Entry_TimeEntry_Bonus", this.dispatchProjectCode, this.dispatchDate);

        return url + queryString;
    }

    public getAddLinkEquipmentUrl(): string {
        const params = this.getDefaultQueryStringParameters();
        params.dispatchIds = JSON.stringify([{
            id: this.dispatchId,
            isEmployee: this.isEmployee,
            DispatchTemplateID: this.dispatchTemplateID,
            DefaultEquipmentActivityCode: this.defaultEquipmentActivityCode,
            DefaultLaborActivityCode: this.defaultLaborActivityCode,
            name: this.empFullName
        }]);

        const queryString = routerHelper.addQuerystring(params);
        const url = routerHelper.navigateTo("Project_Detail_Daily_Entry_Add_Link_Equipmnent", this.dispatchProjectCode, this.dispatchDate, this.dispatchId);
        return url + queryString;
    }

    public getDeleteVisible(index: number): boolean {
        if (this.readonly) {
            return false;
        }

        //JL 13/06/2016: Fix un bug ou la supression d'un pause au milieu d'une journée ne fait rien.
        // On empêche donc la supression d'une pause si elle est entre deux activités qui ne sont pas des pauses.

        const curIsBreak = this.timeEntries[index].ActivityCode === this.PAUSE_ACTIVITY_CODE;

        if (curIsBreak) {
            const hasPrevActivity = index > 0 && this.timeEntries[index - 1].ActivityCode !== this.PAUSE_ACTIVITY_CODE;
            const hasNextActivity = index + 1 < this.timeEntries.length && this.timeEntries[index + 1].ActivityCode !== this.PAUSE_ACTIVITY_CODE;

            return !(hasPrevActivity && hasNextActivity);
        }

        return true;
    }

    public get getTrades(): any  {
        return {
            transport: async (params: any, success: any): Promise<any> => {
                await tradesService.getTradesForEmployee(this.empId, params.data.filter, params.data.page || 1)
                    .then((data: TradeModel[]): any => {
                        return success(_.map(data, (trade: any) => {
                            trade.Id = trade.Code;
                            return trade;
                        }));
                    });
            }
        };
    }

    public async deleteBonus(bonusId: number): Promise<void> {
        if (await notificationHelper.showDeleteConfirmation()) {
            await this.bonusService.DeleteBonus(bonusId);
            location.reload();
        }
    }

    public async deleteLinkedEquipment(dispatchId: number): Promise<void> {
        if (await notificationHelper.showDeleteConfirmation()) {
            await this.dailyEntryLinkEquipmentService.deleteDailyEntryLinkEquipment(dispatchId);
            location.reload();
        }
    }

    public addActivity(timeEntry: any): void {
        routerHelper.navigate(this.getAddActivityUrl(timeEntry));
    }

    public async deleteTimeEntry(timeEntryId: number): Promise<void> {
        if (await notificationHelper.showDeleteConfirmation()) {
            await dispatchTimesheetService.deleteTimeEntry(timeEntryId);
            location.reload();
        }
    }

    public async returnPresent(): Promise<void> {
        await dispatchService.setHasLeft(this.dispatchId, !this.hasLeft).then(() => {
                this.hasLeft = !this.hasLeft;
        });
    }

    public async onTradeSelect(trade: any): Promise<void> {
        await tradesService.setTradeForDispatch(this.dispatchId, trade ? trade.id : 0)
           .then((data: any) => {
               this.tradeCode = data.Code;
               this.tradeDescription = data.Description;
           });
    }

    public navigateToEmployee(): void {
        let url = routerHelper.navigateTo("Project_Detail_Daily_Entry_Employee", this.dispatchProjectCode, this.dispatchDate, this.empId, this.dispatchId);
        url += routerHelper.addQuerystring(this.getDefaultQueryStringParameters());

        routerHelper.navigate(url);
    }

    public async setInactive(): Promise<void> {
        await dispatchService.setInactive(this.dispatchId, !this.inactive).then((data: any) => {
            this.initialize(data, this.dispatch);
        });
    }

    public async inactiveClicked(): Promise<void> {
        if (this.timeEntries.length > 0) {
            if (await notificationHelper.showConfirmation(this.i18n.tr("msg_AbsentConfirmationEquipment"))) {
                this.setInactive();
            }
        } else {
            this.setInactive();
        }
    }

    public async requiredTomorowClicked(): Promise<void> {
        await dispatchService.setRequiredTomorrow(this.dispatchId, !this.requiredTomorrow);
    }

}
