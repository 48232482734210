import { autoinject } from "aurelia-framework";
import contactService from "services/contactService";
import queryStringHelper from "helpers/queryStringHelper";

@autoinject
export class FieldServiceContact {
    public workOrderId: string = "";
    public readonly: boolean = false;
    public actions: any;

    public activate(params: any): any {
        this.bindViewModel(params.workOrderId, params.q);
        return this.loadData();
    }

    public loadData(): any {
        this.actions = {
            getContacts: contactService.getProjectContactsLookupForWorkOrder.bind(this, this.workOrderId)
        };
    }

    public bindViewModel(workOrderId: string, querystring: string): void {
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.workOrderId = workOrderId;
    }
}
