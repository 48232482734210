import additionalFieldsService from "services/additionalFieldsService";
import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import routerHelper from "helpers/routerHelper";

@autoinject
export class FieldServiceAdditionalFieldsType {

    public actions: any = {};
    public workOrderId: number = 0;
    public readonly: boolean = false;
    public additionalFieldsSource: number = AdditionalFieldsSourceType.FieldService;
    public saveAdditionalFieldsButton: boolean = true;
    public workOrderType: string = "";

    constructor(private readonly i18n: I18N, private readonly eventAggregator: EventAggregator) {
    }

    public activate(params: any): any {
        this.bindViewModel(params.workOrderId, params.q);
    }

    public bindViewModel(workOrderId: number, queryString: string): void {
        const params = routerHelper.getQuerystring(queryString);
        this.readonly = !(params && params.readonly === "false");

        this.workOrderId = workOrderId;
        this.workOrderType = params.workOrderType;

        this.eventAggregator.publish("updateTitle", `${this.i18n.tr("pageTitle_Service_Detail_AdditionalFields")} (${this.workOrderType})`);

        this.actions = {
            getAll: additionalFieldsService.getAllForFieldService.bind(this, this.workOrderId),
            setAll: additionalFieldsService.setAllForFieldService.bind(this, this.workOrderId)
        };
    }
}
