import { DocumentModel } from "api/models/company/document/document-model";
import { ServiceDispatchType } from "./../../enums/service-dispatch-type";
import { ServiceCallInspectionAdd } from "./service-call/add";
import val from "core/val";
import urlHelper from "helpers/urlHelper";
import dateHelper from "helpers/dateHelper";
import RouteRepository from "repositories/routeRepository";
import { I18N } from "aurelia-i18n";
import { default as Parse } from "helpers/parse";
import { autoinject, observable } from "aurelia-framework";
import { RouterHelper } from "helpers/router-helper";
import { NotificationHelper } from "helpers/notification-helper";

import { InspectionService } from "services/inspection-service";
import { InspectionSignatureService } from "services/inspection-signature-service";

import { InspectionModel } from "api/models/company/inspection/inspection-model";
import { ContactLookupModel } from "api/models/company/contact/contact-lookup-model";
import { InspectionItemModel } from "api/models/company/inspection/inspection-item-model";

import { InspectionStatus } from "api/enums/inspection-status";
import { inspectionTypes, inspectionResults } from "helpers/enumHelper";
import { InspectionItemValueModel } from "api/models/company/inspection/inspection-item-value-model";

@autoinject()
export class InspectionDetail {
    public val: any = val;
    public dateHelper: any = dateHelper;

    public option: any = "";
    public inspection!: InspectionModel;

    public items: any;
    public btnLinks: any[] | undefined;

    public isReadOnly: boolean = false;
    public isSummary: boolean = false;

    public selectedContact: any;

    public validationMessage: string = "";

    public resultData: any[] = [];
    public showNote: boolean = false;

    public signatureActions: any;
    public dispatchId: any;

    public isServiceCallInspection: boolean = false;
    public params: any;

    @observable public inspectionResult: string | null = "";

    constructor(private readonly i18n: I18N,
                private readonly inspectionService: InspectionService,
                private readonly notificationHelper: NotificationHelper,
                private readonly routeRepository: RouteRepository,
                private readonly routerHelper: RouterHelper,
                private readonly inspectionSignatureService: InspectionSignatureService) {}

    public async activate(params: any): Promise<void> {
        this.params = params;
        await this.load(params);
    }

    public async load(params: any): Promise<void> {
        this.inspection = (await this.inspectionService.GetInspection(params.inspectionId))!;

        this.isServiceCallInspection = this.inspection.VisitNumber > 0;
        if (this.isServiceCallInspection) {
            this.dispatchId = params.dispatchId;
        }

        const isInspectionClosed = this.inspection.Status === InspectionStatus.Closed || this.inspection.Status === InspectionStatus.Complete;
        this.isSummary = params.isSummary || isInspectionClosed;
        this.isReadOnly = params.isReadOnly || this.isSummary;

        this.resultData =  [
        { text: this.i18n.tr("Compliant"), id: inspectionResults.COMPLIANT },
        { text: this.i18n.tr("NotCompliant"), id: inspectionResults.NOTCOMPLIANT }];

        this.inspectionResult = this.inspection.Result;
    }

    public inspectionResultChanged(newValue: string): void {
        if (!newValue) {
            return;
        }

        this.inspection.Result = newValue;
        this.showNote = newValue === inspectionResults.NOTCOMPLIANT;
    }

    public sign(signatureNo: number, signatureName: string): void {
        if (!signatureName || signatureName === "") {
            this.notificationHelper.showWarning(this.i18n.tr("err_SignatureNameRequired"), "");
            return;
        }

        this.routerHelper.navigateToRoute(this.routeRepository.routes.Inspection_Info_Signature.name, { inspectionId: this.inspection.Id, signatureNo: signatureNo, signatureName: signatureName });
    }

    public async onSignatureCompleted(signatureNo: number, signatureName: string, event: any): Promise<void> {
        const signatureData = event.detail;

        if (!signatureName || signatureName.length === 0) {
            this.notificationHelper.showError(this.i18n.tr("err_SignatureRequired"), "", { timeOut: 0 });
        } else {
            const signature: any = {
                Comment: signatureName.replace(" ", " "),
                Email: "",
                FileData: signatureData
            };

            await this.inspectionSignatureService.AddSignatureForInspection(this.inspection.Id, signatureNo, signature);
            await this.load(this.params);
        }

    }

    public get GetContacts(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                this.inspectionSignatureService.GetContactsForSignature(params.data.filter, { page: params.data.page, pageSize: 20 }).then(
                    (result: ContactLookupModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: ContactLookupModel): any => {
                return { id: item.Id, text: item.FullName, data: item };
            }
        };
    }

    public async save(): Promise<void> {
        const isValid = this.validateForm();
        const isResultValid = this.validateResult();

        if (isValid && isResultValid) {
            const result = await this.inspectionService.SaveInspection(this.inspection);
            if (result) {
                this.routerHelper.navigateBack();
            }
        } else {
            this.notificationHelper.showValidationError([this.validationMessage]);
        }
    }

    public async complete(): Promise<void> {
        if (this.isSignaturesValid()) {
            this.notificationHelper.showDialogYesNo(this.i18n.tr("msg_CompleteConfirmation_Inspection")).then(async (value: boolean) => {
                if (value) {
                    if (this.isServiceCallInspection) {
                        this.notificationHelper.showDialogYesNo(this.i18n.tr("msg_IncludeInMessage_Inspection")).then(async (include: boolean) => {
                            await this.inspectionService.Complete(this.inspection.Id, include ? this.inspection.VisitNumber : null);
                            this.replaceBrowsingHistory();
                            this.routerHelper.navigateToRoute(this.routeRepository.routes.Service_Call_Inspections.name, {dispatchId: this.dispatchId, serviceType: ServiceDispatchType.Service});

                        });
                    } else {
                        await this.inspectionService.Complete(this.inspection.Id, null);
                        this.replaceBrowsingHistory();
                        this.routerHelper.navigateToRoute(this.routeRepository.routes.Inspection.name);
                    }
                }
            });
        } else {
            this.notificationHelper.showWarning(this.i18n.tr("err_SignatureRequiredForCompletion"), "");
        }
    }

    private replaceBrowsingHistory(): void {
        const baseUrl = urlHelper.getBaseUrlWithDash();
        let route =  this.routeRepository.routes.Inspection.route.toString();
        if (this.isServiceCallInspection) {
            route =  this.routeRepository.routes.Service_Call_Inspections.route.toString().replace(":dispatchId", this.dispatchId).replace(":serviceType", ServiceDispatchType.Service);
        }
        window.history.replaceState("", "", baseUrl + route);
    }

    private isSignaturesValid(): boolean {
        const isSignature1Valid = this.inspection.Signature1Label && this.inspection.IsMandatorySignature1 ? this.inspection.Signature1Id > 0 : true;
        const isSignature2Valid = this.inspection.Signature2Label && this.inspection.IsMandatorySignature2 ? this.inspection.Signature2Id > 0 : true;
        const isSignature3Valid = this.inspection.Signature3Label && this.inspection.IsMandatorySignature3 ? this.inspection.Signature3Id > 0 : true;

        return isSignature1Valid && isSignature2Valid && isSignature3Valid;
    }

    private validateResult(): boolean {
        if (!this.inspection.Result) {
            this.validationMessage = this.i18n.tr("err_ResultRequired");
            return false;
        }

        if (this.inspection.Result === inspectionResults.NOTCOMPLIANT && !this.inspection.Note) {
            this.validationMessage = this.i18n.tr("err_NoteRequireWhenNotCompliant");
            return false;
        }

        if (this.hasMissingChoiceForMultipleAndSimpleTypes()) {
            this.validationMessage = this.i18n.tr("err_ChoiceNotMade_Inspection");
            return false;
        }

        return true;
    }

    private hasMissingChoiceForMultipleAndSimpleTypes(): boolean {
        return this.inspection.Items!.find((item: InspectionItemModel): any => {
            const type = item.Type!.MeasurementType!.Type;
            if (type === inspectionTypes.SIMPLE || type === inspectionTypes.MULTIPLE) {
                const hasASelectedValue = item!.InspectionItemValues!.find((value: InspectionItemValueModel) => {
                    return value.IsSelected;
                }) !== undefined;

                if (!hasASelectedValue) {
                    return true;
                }
            }
        }) !== undefined;
    }

    private validateForm(): boolean {
        let isValid = true;
        this.validationMessage = "";

        if (this.inspection && this.inspection.Items) {
        this.inspection.Items.forEach((question: InspectionItemModel) => {
            const measurement = (question.Type!.MeasurementType!);
            const type = measurement.Type;

            if (question.Value === undefined) {
                this.validationMessage = this.i18n.tr("err_InspectionAllMesuresRequired");
                isValid = false;
            }

            if (type === inspectionTypes.NUMERIC &&
                measurement.MinimumValue !== 0 &&
                measurement.MaximumValue !== 0 &&
                (Number.parseInt(question.Value!) < measurement.MinimumValue || Number.parseInt(question.Value!) > measurement.MaximumValue)
            ) {
                this.validationMessage = this.i18n.tr("err_InspectionNumericBetween")
                    .replace("[mesure]", measurement.Description!)
                    .replace("[min]", measurement.MinimumValue.toString())
                    .replace("[max]", measurement.MaximumValue.toString());
                isValid = false;
            }

            if (type === inspectionTypes.CHECK && Parse.Boolean(question.Value) === false && !question.Note) {
                this.validationMessage = this.i18n.tr("err_InspectionNoteRequiredForFalseAnswer");
                isValid = false;
            }

            if (type === inspectionTypes.YESNO && question.Value === "N" && !question.Note) {
                this.validationMessage = this.i18n.tr("err_InspectionNoteRequiredForNoAnswer");
                isValid = false;
            }

            });
        }

        return isValid;
    }
}
