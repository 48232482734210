import { I18N } from "aurelia-i18n";
import { autoinject, singleton } from "aurelia-framework";
import { Redirect } from "aurelia-router";

import { default as _ } from "underscore";

import { default as resx } from "core/resx";
import { default as dateHelper } from "helpers/dateHelper";
import { default as enumHelper } from "helpers/enumHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as settingHelper } from "helpers/settingHelper";
import { default as defaultService } from "services/defaultService";
import { default as workOrderService } from "services/workOrderService";

@autoinject
export class FieldServiceIndex {
    public resx: any = resx;
    public routerHelper: any = routerHelper;
    public WORKORDER_STATUS: enumHelper.workOrderStatus = enumHelper.workOrderStatus();
    public cachedListPrevious: any[] = [];
    public cachedListOnGoing: any[] = [];
    public list: any[] = [];
    public loadingStatus: any = []; // True if the end of the infinite scrolling is reached for current tab
    public page: number = 1;
    public sectionShown: number = this.WORKORDER_STATUS.ONGOING.id;
    public scrollMessageText: string = this.i18n.tr("ScrollForMore");
    public showScrollMessage: boolean = false;
    public workOrderStatus: enumHelper.workOrderStatus | undefined = undefined;
    public workOrderStatusValues: any[] = [];

    constructor(private readonly i18n: I18N) {
    }

    public bindViewModel(): void {
        this.workOrderStatus = this.WORKORDER_STATUS;
        this.workOrderStatusValues = _.values(this.WORKORDER_STATUS);
    }

    public load(): any {
        this.loadData(this.sectionShown === this.WORKORDER_STATUS.COMPLETED.id);
    }

    public async loadData(previous: boolean): Promise<void> {
        this.page = 1;
        const ls = await this.getData(previous, this.page);

        if (previous) {
            this.cachedListPrevious = ls;
        } else {
            this.cachedListOnGoing = ls;
        }

        this.list = ls;
    }

    public async getData(previous: boolean, page: number): Promise<any> {
        return this.getDataDone(await workOrderService.list(previous, page));
    }

    public getDataDone(data: any): any {
        const itemCount: number = _.reduce(data,
            (aggregate: any, cur: any) => {
                return cur.WorkOrders.length + aggregate;
            }, 0);

        if (itemCount >= defaultService.getPageSize()) {
            this.loadingStatus[this.sectionShown] = false;
            this.initScroll();
        } else {
            this.loadingStatus[this.sectionShown] = true;
            this.disposeScroll();
        }

        return this.mapData(data);
    }

    public mapData(data: any): any {
        return _.map(data,
            (item: { Date: Date | string; isOpened: boolean; formattedDate: string }) => {
                item.isOpened = true;
                item.formattedDate = dateHelper.getFullTextDate(item.Date);

                return item;
            });
    }

    public disposeAll(): void {
        this.cachedListPrevious = [];
        this.cachedListOnGoing = [];
        this.list = [];
        this.disposeScroll();
    }

    public initScroll(): void {
        jQuery(document).scroll(this.scrollHandler.bind(this));
        this.showScrollMessageFunction("scroll");
    }

    public disposeScroll(): void {
        jQuery(document).off("scroll");
        this.hideScrollMessage();
    }

    public scrollHandler: () => void = async (): Promise<void> => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            this.disposeScroll();
            this.showScrollMessageFunction("loading");
            this.page += 1;
            await this.getData(this.sectionShown === this.WORKORDER_STATUS.COMPLETED.id, this.page)
                .then((ls: any) => {
                    let actualLs: any[] = this.list;
                    actualLs = actualLs.concat(ls);
                    this.list = actualLs;
                });
        }
    }

    public showScrollMessageFunction(msgId: string): void {
        if (msgId === "loading") {
            this.scrollMessageText = this.i18n.tr("LoadingMoreResults");
        } else {
            this.scrollMessageText = this.i18n.tr("ScrollForMore");
        }

        this.showScrollMessage = true;
    }

    public hideScrollMessage(): void {
        this.showScrollMessage = false;
    }

    public canActivate(): boolean | Redirect {
        if (!settingHelper.hasDispatchModel()) {
            notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"));
            return new Redirect("Settings");
        }

        return true;
    }

    public async activate(): Promise<void> {
        this.bindViewModel();
        await this.load();
    }

    public addWorkOrder(): void {
        const url: string = routerHelper.navigateTo("FieldService_Detail_Edit", 0) +
            routerHelper.addQuerystring({ editMode: "add", readonly: false });
        routerHelper.navigate(url);
    }

    public navigateToDetail(id: any): void {
        routerHelper.navigate(routerHelper.getRelativeUrl("", id));
    }

    public tabClicked(status: any): void {
        this.sectionShown = status.id;
        this.load();
    }

    // public toggleOpenDrawer(drawer: any): void {
    //     drawer.isOpened = !drawer.isOpened;
    // }

    public openAddress(data: any, event: any): void {
        event.cancelBubble = true;
        if (event.stopPropagation) {
            event.stopPropagation();
        }

        window.open(routerHelper.mapAddress(data.LocationAddress +
            ", " +
            data.LocationCity +
            " (" +
            data.LocationProvince +
            ") " +
            data.LocationPostalCode));
    }

    public getStatusLabel(isCompleted: boolean): string {
        return isCompleted ? this.i18n.tr("Completed") : this.i18n.tr("Ongoing");
    }
}
