import { ReceiptOfGoodsService } from "./../../services/receipt-of-goods-service";
import RouteRepository from "repositories/routeRepository";
import { I18N } from "aurelia-i18n";
import { autoinject, bindable } from "aurelia-framework";

import { Tab } from "components/layout/tab";

import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";

import { ReceiptOfGoodsModel } from "api/models/company/receipt-of-goods-model";
import { MaCardLineModel, MaCardOptionsModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";

@autoinject
export class ReceiptOfGoodsIndex {
    @bindable public currentTabId: number = 0;
    public isCompletedReceptionsLoaded: boolean = false;

    public onGoingReceptionsCards: MaCardOptionsModel[] = [];
    public closedReceptionsCards: MaCardOptionsModel[] = [];
    public orderId: string | null = null;
    public extension: string = "";

    constructor(private readonly i18n: I18N, private readonly receiptOfGoodsService: ReceiptOfGoodsService, private routeRepository: RouteRepository) {}

    public async attached(): Promise<void> {
        if (!this.orderId) {
            const result = await this.receiptOfGoodsService.GetReceiptsOfGoods(false);
            result!.forEach((item: ReceiptOfGoodsModel) => {
                this.onGoingReceptionsCards.push(this.createCard(item, false));
            });
        } else {
            const result = await this.receiptOfGoodsService.GetReceiptsOfGoodsForOrder(false, this.orderId, this.extension === "" ? null : this.extension);
            result!.forEach((item: ReceiptOfGoodsModel) => {
                this.onGoingReceptionsCards.push(this.createCard(item, true));
            });
        }
        
    }

    public activate(params: any): void {
        this.orderId = !params.orderId ? null : params.orderId;
        this.extension = !params.extension ? "" : params.extension;
    }

    public currentTabIdChanged(): void {
        if (!this.isCompletedReceptionsLoaded) {
            if (!this.orderId) {
                this.receiptOfGoodsService.GetReceiptsOfGoods(true).then((result: ReceiptOfGoodsModel[]): any => {
                    result.forEach((item: ReceiptOfGoodsModel) => {
                        this.closedReceptionsCards.push(this.createCard(item, true));
                    });
                });
            } else {
                this.receiptOfGoodsService.GetReceiptsOfGoodsForOrder(true, this.orderId, this.extension === "" ? null : this.extension).then((result: ReceiptOfGoodsModel[]): any => {
                    result.forEach((item: ReceiptOfGoodsModel) => {
                        this.closedReceptionsCards.push(this.createCard(item, true));
                    });
                });
            }
            
            this.isCompletedReceptionsLoaded = true;
        }
    }

    public async selectedTabChanged(event: CustomEvent<Tab>): Promise<void> {
        if (Number(event.detail.tabId) === this.currentTabId) {
            return;
        }
        this.currentTabId = Number(event.detail.tabId);
    }

    public add(): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Receipt_Of_Goods_Order_Reception_Edit.name, { receiptId: 0 });
    }

    public edit(item: ReceiptOfGoodsModel): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Receipt_Of_Goods_Order_Reception_Edit.name, { receiptId: item.Id, orderId: this.orderId });
    }

    public delete(item: ReceiptOfGoodsModel): void {
        const dialogMessage = `${this.i18n.tr("msg_DeleteReceiptOfGoodConfirmationText")} (${this.getOrderSupplierDisplay(item)})`;

        notificationHelper.showDialogYesNo(dialogMessage).then((success: boolean): any => {
            if (success) {
                this.receiptOfGoodsService.DeleteReceiptOfGoods(item.Id).then((result: boolean): any => {
                    if (result) {
                        this.removeReceptionFromList(item.Id);
                        notificationHelper.showSuccess(this.i18n.tr("Procore.Status_Success"));
                    }
                });
            }
        });
    }

    public createCard(item: ReceiptOfGoodsModel, isClosed: boolean): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            class: "ma-card-link",
            action: this.edit.bind(this, item),
            displayLines: [
                new MaCardLineModel({ display: this.getOrderSupplierDisplay(item), class: "text-primary font-weight-bold" }),
                new MaCardLineModel({ display: this.getOrderCounterDisplay(item) }),
                new MaCardLineModel({ display: this.getOrderDateDisplay(item), icon: "fa fa-plus text-success pr-1" }),
                new MaCardLineModel({ display: this.getOrderStatusDisplay(item), class: "font-weight-bold" }),
                new MaCardLineModel({ display: this.getCreatedByDisplay(item), class: "mt-2" }),
            ],
            actionItems: [new MaCardActionModel({ icon: "fa fa-chevron-right", action: this.edit.bind(this, item) })],
        });

        if (!isClosed) {
            card.actionItems!.push(new MaCardActionModel({ id: "delete", icon: "fa fa-trash text-danger pt-3", action: this.delete.bind(this, item) }));
        }
        return card;
    }

    public getOrderSupplierDisplay(item: ReceiptOfGoodsModel): string {
        const capitalizedExtensionString = this.i18n.tr("ExtensionShort:").charAt(0).toUpperCase() +  this.i18n.tr("ExtensionShort:").slice(1);
        const extensionDisplay = item.Extension ? ` ${capitalizedExtensionString} ${item.Extension}` : "";

        return `${item.OrderNumber}${extensionDisplay} - ${item.Supplier}`;
    }

    public getOrderCounterDisplay(item: ReceiptOfGoodsModel): string {
        return `${this.i18n.tr("Counter")}: ${item.Id}`;
    }

    public getOrderDateDisplay(item: ReceiptOfGoodsModel): string {
        return `${this.i18n.tr("Added")} ${dateHelper.formatDate("DD MMM YYYY", item.ReceiptDate!)}`;
    }

    public getOrderStatusDisplay(item: ReceiptOfGoodsModel): string {
        return item.Status === "0" ? this.i18n.tr("InProgress") : this.i18n.tr("Completed");
    }

    public getCreatedByDisplay(item: ReceiptOfGoodsModel): string {
        return `${this.i18n.tr("CreatedBy")} ${item.CreatedBy}`;
    }

    private removeReceptionFromList(id: number): void {
        this.onGoingReceptionsCards = this.onGoingReceptionsCards.filter((item: MaCardOptionsModel) => {
            return id !== item.model.Id;
        });
    }
}
