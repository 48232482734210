import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import documentHelper from "helpers/documentHelper";
import routerHelper from "helpers/routerHelper";
import { DocumentService } from "services/document-service";
import documentService from "services/documentService";

@autoinject
export class FieldServiceDocument {

    public readonly: boolean = false;
    public workOrderId: string = "";
    public actions: any;

    constructor(private readonly i18n: I18N, private readonly documentServ: DocumentService) {

    }

    public activate(params: any): any {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.workOrderId = params.workOrderId;

        this.actions = {
            getSections: documentService.getSectionsByWorkOrderId.bind(this, this.workOrderId),
            getSectionDocuments: documentService.getDocumentsByWorkOrderId.bind(this, this.workOrderId),
            getDocumentUrl: documentHelper.getDocumentUrlById.bind(this),
            deleteDocument: this.documentServ.DeleteDocument.bind(this.documentServ)
        };
    }
}
