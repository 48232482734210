import { singleton } from "aurelia-dependency-injection";
import { PLATFORM } from "aurelia-pal";
import { activationStrategy, RouteConfig } from "aurelia-router";
import { default as _ } from "underscore";

export const NavigationNew = "new";
export type NavigationIdOrNew<T> = T | "new";

export interface SpecificRouteConfig extends RouteConfig {
    name: string;  // Make non-optional

    titleKey?: string;
    showOnHomePage?: boolean;
    hideOnSmallScreen?: boolean;
    menuIcon?: string;
    homeIcon?: string;
    showBack?: boolean;
    hideHeader?: boolean;
    access?: string;
}

// TODO ML: Renommer ça, ce n'est pas un repository.
@singleton()
export default class RouteRepository {
    public routes: { [key: string]: SpecificRouteConfig } = {
        Home:                     { name: "Home",                     route: ["home", ""],               moduleId: PLATFORM.moduleName("pages/home"),                                  titleKey: "Home",                     showOnHomePage: false, nav: 1,                  menuIcon: "ma ma-home", showBack: false } as SpecificRouteConfig,
        Service:                  { name: "Service",                  route: "service",                  moduleId: PLATFORM.moduleName("pages/services/index"),                        titleKey: "Service",                  showOnHomePage: true,  nav: 110,                menuIcon: "ma ma-service",                     homeIcon: "ma ma-service-circle",                   activationStrategy: activationStrategy.invokeLifecycle },
        Project:                  { name: "Project",                  route: "project",                  moduleId: PLATFORM.moduleName("pages/projects/index"),                        titleKey: "Projects",                 showOnHomePage: true,  nav: 100, legacy: false, menuIcon: "ma ma-project",                     homeIcon: "ma ma-project-circle" },
        FieldService:             { name: "FieldService",             route: "fieldservice",             moduleId: PLATFORM.moduleName("pages/fieldservices/index"),                   titleKey: "FieldService",             showOnHomePage: true,  nav: 120, legacy: false, menuIcon: "ma ma-fieldservice",                homeIcon: "ma ma-fieldservice-circle",              activationStrategy: activationStrategy.invokeLifecycle },
        Recommendation:           { name: "Recommendation",           route: "recommendation",           moduleId: PLATFORM.moduleName("pages/recommendation"),                        titleKey: "Recommendations",          showOnHomePage: true,  nav: 125, legacy: false, menuIcon: "ma ma-recommendation",              homeIcon: "ma ma-recommendation-circle" },
        Inspection:               { name: "Inspection",               route: "inspections",              moduleId: PLATFORM.moduleName("pages/inspections/index"),                     titleKey: "Inspections",              showOnHomePage: true,  nav: 128, legacy: false, menuIcon: "ma ma-inspection",                  homeIcon: "ma ma-inspection-circle" },
        Approval:                 { name: "Approval",                 route: "approval",                 moduleId: PLATFORM.moduleName("pages/approval"),                              titleKey: "Approvals",                showOnHomePage: true,  nav: 128, legacy: false, menuIcon: "ma ma-approval",                    homeIcon: "ma ma-approval-circle" },
        Timesheet:                { name: "Timesheet",                route: "timesheet",                moduleId: PLATFORM.moduleName("pages/timesheet/index"),                       titleKey: "Timesheets",               showOnHomePage: true,  nav: 129, legacy: false, menuIcon: "ma ma-timesheet",                   homeIcon: "ma ma-timesheet-circle",                  activationStrategy: activationStrategy.invokeLifecycle },
        ReceiptOfGoods:           { name: "ReceiptOfGoods",           route: "ReceiptOfGoods",           moduleId: PLATFORM.moduleName("pages/receipt-of-goods/index"),                titleKey: "ReceiptOfGoods",           showOnHomePage: true,  nav: 130, legacy: false, menuIcon: "ma ma-receiptOfGoods",              homeIcon: "ma ma-receiptOfGoods-circle",             activationStrategy: activationStrategy.invokeLifecycle },
        TimeKeeping:              { name: "TimeKeeping",              route: "TimeKeeping",              moduleId: PLATFORM.moduleName("pages/timeKeeping/index"),                     titleKey: "PunchInOut",               showOnHomePage: true,  nav: 133, legacy: false, menuIcon: "ma ma-punchInOut",                  homeIcon: "ma ma-punchInOut-circle",                 activationStrategy: activationStrategy.invokeLifecycle },
        InventoryProjectTransfer: { name: "InventoryProjectTransfer", route: "InventoryProjectTransfer", moduleId: PLATFORM.moduleName("pages/inventory-project-transfer/index"),      titleKey: "InventoryProjectTransfer", showOnHomePage: true,  nav: 133, legacy: false, menuIcon: "ma ma-inventory-project-transfer",  homeIcon: "ma ma-inventory-project-transfer-circle", activationStrategy: activationStrategy.invokeLifecycle },
        InventorySiteTransfer:    { name: "InventorySiteTransfer",    route: "InventorySiteTransfer",    moduleId: PLATFORM.moduleName("pages/inventory-site-transfer/index"),         titleKey: "InventorySiteTransfer",    showOnHomePage: true,  nav: 134, legacy: false, menuIcon: "ma ma-inventory-site-transfer",     homeIcon: "ma ma-inventory-site-transfer-circle",    activationStrategy: activationStrategy.invokeLifecycle },
        AssignmentCalendar:       { name: "AssignmentCalendar",       route: "calendar",                 moduleId: PLATFORM.moduleName("pages/assignment-calendar"),                   titleKey: "AssignmentCalendar",       showOnHomePage: false, nav: 140,                menuIcon: "fa fa-calendar",                    showBack: false,                                     activationStrategy: activationStrategy.invokeLifecycle },
        Profile:                  { name: "Employee_Profile",         route: "profile",                  moduleId: PLATFORM.moduleName("pages/employee-profile"),                      titleKey: "pageTitle_Employee_Profile", showOnHomePage: false, nav: 145,              menuIcon: "fa fa-user",                        showBack: true },
        Settings:                 { name: "Settings",                 route: "settings",                 moduleId: PLATFORM.moduleName("pages/settings"),                              titleKey: "Settings",                 showOnHomePage: false, nav: 160,                menuIcon: "ma ma-cog",                         showBack: false },
        Help:                     { name: "Help",                     route: "help",                     redirect: "",                                                                 titleKey: "Help",                     showOnHomePage: false, nav: 150,                menuIcon: "fa fa-info-circle",                 homeIcon: "fa fa-info-circle", showBack: false },
        Logout:                   { name: "Logout",                   route: "logout",                   redirect: "",                                                                 titleKey: "Logout",                   showOnHomePage: false, nav: 200,                menuIcon: "ma ma-sign-out",                    showBack: false },
        Login:                    { name: "Login",                    route: "login",                    moduleId: PLATFORM.moduleName("pages/login"), hideHeader: true, showBack: false },
        BrowserNotSupported:      { name: "BrowserNotSupported",      route: "browserNotSupported",      moduleId: PLATFORM.moduleName("pages/browserNotSupported"), nav: -1, showBack: false },
        Error:                    { name: "Error",                    route: "error",                    moduleId: PLATFORM.moduleName("pages/error"), nav: -1, hideHeader: true, showBack: false },
        ErrorVersion:             { name: "ErrorVersion",             route: "error-version",            moduleId: PLATFORM.moduleName("pages/error-version"), nav: -1, hideHeader: true, showBack: false },

        MaestroBI_Home: {
            name: "MaestroBI_Home",
            route: "maestro-bi/home",
            moduleId: PLATFORM.moduleName("pages/maestro-bi/home"),
            titleKey: "MaestroBI_Home",
            showOnHomePage: true,
            hideOnSmallScreen: true,
            nav: 135,
            menuIcon: "ma ma-maestroBi",
            homeIcon: "ma ma-maestroBi-circle",
            activationStrategy: activationStrategy.invokeLifecycle
        },

        MaestroBI_Export: {
            name: "MaestroBI_Export",
            route: "maestro-bi/export",
            moduleId: PLATFORM.moduleName("pages/maestro-bi/export"),
        },

        MaestroBI_Import: {
            name: "MaestroBI_Import",
            route: "d/maestro-bi/import",
            moduleId: PLATFORM.moduleName("pages/maestro-bi/import"),
        },

        Error_Hosted: {
            name: "Error_Hosted",
            route: "error_hosted",
            moduleId: PLATFORM.moduleName("pages/errorHosted"),
        },

        Document_Edit:           { name: "Document_Edit", route: "document/edit", moduleId: PLATFORM.moduleName("pages/document_edit") },

        // Field Service
        FieldService_Address: {
            name: "FieldService_Address",
            route: "fieldservice/:workOrderId/address",
            moduleId: PLATFORM.moduleName("pages/fieldservices/address"),
        },
        FieldService_Attendance: {
            name: "FieldService_Attendance",
            route: "fieldservice/:workOrderId/attendance",
            moduleId: PLATFORM.moduleName("pages/fieldservices/attendance"),
        },
        FieldService_Attendance_Absence: {
            name: "FieldService_Attendance_Absence",
            route: "fieldservice/:workOrderId/attendance/absence/:dispatchId",
            moduleId: PLATFORM.moduleName("pages/fieldservices/attendance_absence"),
        },
        FieldService_Contact: {
            name: "FieldService_Contact",
            route: "fieldservice/:workOrderId/contact",
            moduleId: PLATFORM.moduleName("pages/fieldservices/contact"),
        },
        FieldService_Contact_Detail: {
            name: "FieldService_Contact_Detail",
            route: "fieldservice/:workOrderId/contact/detail/:id",
            moduleId: PLATFORM.moduleName("pages/fieldservices/contact_detail"),
        },
        FieldService_Detail: {
            name: "FieldService_Detail",
            route: "fieldservice/:workOrderId",
            moduleId: PLATFORM.moduleName("pages/fieldservices/detail"),
        },
        FieldService_Detail_Edit: {
            name: "FieldService_Detail_Edit",
            route: "fieldservice/:workOrderId/edit",
            moduleId: PLATFORM.moduleName("pages/fieldservices/detail_edit"),
        },
        FieldService_Document: {
            name: "FieldService_Document",
            route: "fieldservice/:workOrderId/document",
            moduleId: PLATFORM.moduleName("pages/fieldservices/document"),
        },
        FieldService_Document_Add: {
            name: "FieldService_Document_Add",
            route: "fieldservice/:workOrderId/document/add",
            moduleId: PLATFORM.moduleName("pages/fieldservices/document_add"),
        },
        FieldService_Employee: {
            name: "FieldService_Employee",
            route: "fieldservice/:workOrderId/employee/:employeeId/:dispatchId",
            moduleId: PLATFORM.moduleName("pages/fieldservices/employee"),
        },

        FieldService_Hour:                               { name: "FieldService_Hour", route: "fieldservice/:workOrderId/hour", moduleId: PLATFORM.moduleName("pages/fieldservices/hour") },
        FieldService_Hour_Edit:                          { name: "FieldService_Hour_Edit", route: "fieldservice/:workOrderId/hour/edit/:id?", moduleId: PLATFORM.moduleName("pages/fieldservices/hour_edit") },
        FieldService_Hour_Employee:                      { name: "FieldService_Hour_Employee", route: "fieldservice/:workOrderId/hour/:isEmployee/:id", moduleId: PLATFORM.moduleName("pages/fieldservices/hourEmployee") },

        //FieldService_List:                               { name: "FieldService_List", route: "fieldservice/:workorderid/list", moduleId: PLATFORM.moduleName("pages/fieldservices/list") },

        FieldService_Recommendation:                     { name: "FieldService_Recommendation", route: "fieldservice/:workOrderId/recommendation", moduleId: PLATFORM.moduleName("pages/fieldservices/recommendation") },
        FieldService_Recommendation_Edit:                { name: "FieldService_Recommendation_Edit", route: "fieldservice/:workOrderId/recommendation/edit/:recommendationId?", moduleId: PLATFORM.moduleName("pages/fieldservices/recommendation_edit") },
        FieldService_Recommendation_Edit_Documents_Add:  { name: "FieldService_Recommendation_Edit_Documents_Add", route: "fieldservice/:workOrderId/recommendation/edit/:recommendationId/documents/add", moduleId: PLATFORM.moduleName("pages/fieldservices/recommendation_edit_document_add") },

        FieldService_Material:                           { name: "FieldService_Material", route: "fieldservice/:workOrderId/material", moduleId: PLATFORM.moduleName("pages/fieldservices/material") },
        FieldService_Material_Edit: { name: "FieldService_Material_Edit", route: "fieldservice/:workOrderId/material/edit/:isBilling/:lineNo?", moduleId: PLATFORM.moduleName("pages/fieldservices/material-edit") },

        FieldService_MaterialOrder: { name: "FieldService_MaterialOrder", route: "fieldservice/:workOrderId/materialOrder", moduleId: PLATFORM.moduleName("pages/fieldservices/material-order/materialOrder") },
        FieldService_MaterialOrder2: { name: "FieldService_MaterialOrder", route: "fieldservice/:workOrderId/materialOrder/edit/:orderId?", moduleId: PLATFORM.moduleName("pages/fieldservices/material-order/materialOrder_edit") },
        FieldService_MaterialOrder3: { name: "FieldService_MaterialOrder", route: "fieldservice/:workOrderId/materialOrder/edit/:orderId/item/:orderDate/:lineNo?", moduleId: PLATFORM.moduleName("pages/fieldservices/material-order/material-order-item") },

        FieldService_Detail_MaterialOrder_document:      { name: "FieldService_Detail_MaterialOrder_document",     route: "fieldservice/:workOrderId/materialOrder/edit/:orderId/document/",    moduleId: PLATFORM.moduleName("pages/fieldservices/material-order/material-order-document") },
        FieldService_Detail_MaterialOrder_document_add: { name: "FieldService_Detail_MaterialOrder_document_add", route: "fieldservice/:workOrderId/materialOrder/edit/:orderId/document/add", moduleId: PLATFORM.moduleName("pages/fieldservices/material-order/material-order-document_add") },
        FieldService_Detail_MaterialOrder_memo: { name: "Receipt_Of_Goods_Memo", route: "fieldservice/:workOrderId/materialOrder/edit/:orderId/memo", moduleId: PLATFORM.moduleName("pages/fieldservices/material-order/material-order-memo") },
        FieldService_Detail_MaterialOrder_AdditionalFields: { name: "FieldService_PurchaseOrder_AdditionalFields", route: "fieldservice/:workOrderId/materialOrder/edit/:orderId/additionalFields", moduleId: PLATFORM.moduleName("pages/fieldservices/material-order/additionalFields") },

        FieldService_Detail_MaterialOrder_Summary:   { name: "FieldService_MaterialOrder_Summary", route: "fieldservice/:workOrderId/materialOrder/edit/summary/:orderId?", moduleId: PLATFORM.moduleName("pages/fieldservices/material-order/materialOrder_summary") },
        FieldService_Detail_MaterialOrder_Summary_Item: { name: "FieldService_MaterialOrder_Summary_Item", route: "fieldservice/:workOrderId/materialOrder/edit/summary/:orderId/item/:orderDate/:lineNo?", moduleId: PLATFORM.moduleName("pages/fieldservices/material-order/material-order-item") },

        FieldService_WorkTask: { name: "FieldService_WorkTask", route: "fieldservice/:id/workTask", moduleId: PLATFORM.moduleName("pages/fieldservices/work-task-index") },
        FieldService_WorkTask_Add: { name: "FieldService_WorkTask_Add", route: "fieldservice/:id/workTask/edit", moduleId: PLATFORM.moduleName("pages/fieldservices/work-task-edit") },
        FieldService_WorkTask_Edit: { name: "FieldService_WorkTask_Edit", route: "fieldservice/:id/workTask/edit/:transactionNumber", moduleId: PLATFORM.moduleName("pages/fieldservices/work-task-edit") },
        FieldService_WorkTask_Documents_Add: { name: "FieldService_WorkTask_Documents_Add", route: "fieldservice/:id/workTask/edit/:transactionNumber/document/add", moduleId: PLATFORM.moduleName("pages/fieldservices/work-task-edit-documents-add") },

        // TODO ML: Plusieurs fois le même nom!
        Project_Detail_MaterialRequisition:              { name: "Project_Detail_MaterialRequisition", route: "fieldservice/:workOrderId/materialRequisition", moduleId: PLATFORM.moduleName("pages/fieldservices/materialRequisition") },
        Project_Detail_MaterialRequisition2:             { name: "Project_Detail_MaterialRequisition", route: "fieldservice/:workOrderId/materialRequisition/edit/:requisitionId?", moduleId: PLATFORM.moduleName("pages/fieldservices/materialRequisition_edit") },
        Project_Detail_MaterialRequisition3:             { name: "Project_Detail_MaterialRequisition", route: "fieldservice/:workOrderId/materialRequisition/edit/:requisitionId/item/:requisitionDate/:lineNo?", moduleId: PLATFORM.moduleName("pages/fieldservices/material-requisition-item") },

        FieldService_AdditionalFields:                   { name: "FieldService_AdditionalFields", route: "fieldservice/:workOrderId/additionalFields", moduleId: PLATFORM.moduleName("pages/fieldservices/additionalFields") },
        FieldService_AdditionalFieldsType:                   { name: "FieldService_AdditionalFields", route: "fieldservice/:workOrderId/additionalFieldsType", moduleId: PLATFORM.moduleName("pages/fieldservices/additionalFieldsType") },

        FieldService_Summary:                            { name: "FieldService_Summary", route: "fieldservice/:workOrderId/summary", moduleId: PLATFORM.moduleName("pages/fieldservices/summary") },

        // Project
        Project_Detail_Hours_Summary:                    { name: "Project_Detail_Hours_Summary", route: "project/hoursSummary", moduleId: PLATFORM.moduleName("pages/projects/hoursSummary"), /*access: "isProjectResponsible=true"*/ },

        Project_Detail:                                  { name: "Project_Detail", route: "project/:id", moduleId: PLATFORM.moduleName("pages/projects/detail") },

        // TODO ML: Plusieurs fois le même nom!
        Project_Detail_Contact:                          { name: "Project_Detail_Contact", route: "project/:dispatchProjectCode/contact", moduleId: PLATFORM.moduleName("pages/projects/contact") },
        Project_Detail_Contact2:                         { name: "Project_Detail_Contact", route: "project/:dispatchProjectCode/contact/detail/:id", moduleId: PLATFORM.moduleName("pages/projects/contact-detail") },

        Project_Detail_Document:                         { name: "Project_Detail_Document", route: "project/:dispatchProjectCode/documents", moduleId: PLATFORM.moduleName("pages/projects/documents") },

        Project_Detail_Information:                      { name: "Project_Detail_Information", route: "project/:dispatchProjectCode/information", moduleId: PLATFORM.moduleName("pages/projects/information") },

        // TODO ML: Plusieurs fois le même nom!
        Project_Detail_MaterialRequisition4:             { name: "Project_Detail_MaterialRequisition", route: "project/:dispatchProjectCode/materialRequisition", moduleId: PLATFORM.moduleName("pages/projects/materialRequisition") },
        Project_Detail_MaterialRequisition5:             { name: "Project_Detail_MaterialRequisition", route: "project/:dispatchProjectCode/materialRequisition/edit/:requisitionId?", moduleId: PLATFORM.moduleName("pages/projects/materialRequisition_edit") },
        Project_Detail_MaterialRequisition6:             { name: "Project_Detail_MaterialRequisition", route: "project/:dispatchProjectCode/materialRequisition/edit/:requisitionId/item/:date/:lineNo?", moduleId: PLATFORM.moduleName("pages/projects/material-requisition-item") },
        Project_Detail_MaterialRequisition7:             { name: "Project_Detail_MaterialRequisition", route: "project/:dispatchProjectCode/materialRequisition/edit/:requisitionId/projectResource/:date/:lineNo?", moduleId: PLATFORM.moduleName("pages/projects/material-requisition-project-resource") },

        Common_Detail_Recommendation:                    { name: "Common_Detail_Recommendation", route: "project/:dispatchProjectCode/recommendation", moduleId: PLATFORM.moduleName("pages/projects/recommendation") },
        Common_Detail_Recommendation_Edit:               { name: "Common_Detail_Recommendation_Edit", route: "project/:dispatchProjectCode/recommendation/edit/:recommendationId?", moduleId: PLATFORM.moduleName("pages/projects/recommendation_edit") },
        Common_Detail_Recommendation_Edit_Documents_Add: { name: "Common_Detail_Recommendation_Edit_Documents_Add", route: "project/:dispatchProjectCode/recommendation/edit/:recommendationId/documents/add", moduleId: PLATFORM.moduleName("pages/projects/recommendation_edit_document_add") },

        Common_Detail_ShopDrawings:                      { name: "Common_Detail_ShopDrawings", route: "project/:dispatchProjectCode/shopDrawings", moduleId: PLATFORM.moduleName("pages/projects/shop-drawings") },
        Common_Detail_ShopDrawings_Edit:                 { name: "Common_Detail_ShopDrawings_Edit", route: "project/:dispatchProjectCode/shopDrawings/edit/:drawingId?", moduleId: PLATFORM.moduleName("pages/projects/shop-drawing-edit") },

        // Project - Daily Entry
        Project_Detail_Daily_Entry_add:                  { name: "Project_Detail_Daily_Entry_add", route: "project/:dispatchProjectCode/dailyEntry/add", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/dailyEntry_add"), access: "isProjectResponsible=true" },
        Project_Detail_Daily_Entry_Existing:             { name: "Project_Detail_Daily_Entry_Existing", route: "project/:dispatchProjectCode/dailyEntry/add/existing", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/dailyEntry_existing") },
        Project_Detail_Daily_Entry:                      { name: "Project_Detail_Daily_Entry", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/dailyEntry") },
        Project_Detail_Daily_Entry_Weather_Edit:         { name: "Project_Detail_Daily_Entry_Weather_Edit", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/weather/edit", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/dailyEntryWeather_edit"), access: "isProjectResponsible=true" },
        Project_Detail_Daily_Entry_Work_Edit:            { name: "Project_Detail_Daily_Entry_Work_Edit", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/work/edit", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/dailyEntryWork_edit"), access: "isProjectResponsible=true" },

        // TODO ML: Plusieurs fois le même nom!
        Project_Detail_Daily_Entry_Document:             { name: "Project_Detail_Daily_Entry_Document", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/document", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/document") },
        Project_Detail_Daily_Entry_Document2:            { name: "Project_Detail_Daily_Entry_Document", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/document/add", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/document_add") },

        Project_Detail_Daily_Entry_Attendance:           { name: "Project_Detail_Daily_Entry_Attendance", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/attendance", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/attendance") },
        Project_Detail_Daily_Entry_Attendance_Absence:   { name: "Project_Detail_Daily_Entry_Attendance_Absence", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/attendance/absence/:dispatchId", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/attendance_absence") },

        Project_Detail_Daily_Entry_Messages:           { name: "Project_Detail_Daily_Entry_Messages", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/messages/:dispatchTemplateCode", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/messages") },

        Project_Detail_Daily_Entry_EmployeeEvaluation_Add:      { name: "Project_Detail_Daily_Entry_EmployeeEvaluation_Add", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/employee/evaluation/add", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/employeeEvaluation_add"), access: "isProjectResponsible=true" },
        Project_Detail_Daily_Entry_Employee:                    { name: "Project_Detail_Daily_Entry_Employee", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/employee/:employeeId/:dispatchId", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/employee") },

        Project_Detail_Daily_Entry_Hours_Summary:               { name: "Project_Detail_Daily_Entry_Hours_Summary", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/hoursSummary", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/hoursSummary"), access: "isProjectResponsible=true" },

        Project_Detail_Daily_Entry_Interruption:                { name: "Project_Detail_Daily_Entry_Interruption", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/interruption", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/interruption"), access: "isProjectResponsible=true" },
        Project_Detail_Daily_Entry_Interruption_Edit:           { name: "Project_Detail_Daily_Entry_Interruption_Edit", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/interruption/edit/:id?", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/interruption_edit"), access: "isProjectResponsible=true" },

        Project_Detail_Daily_Entry_LaborRequisition:            { name: "Project_Detail_Daily_Entry_LaborRequisition", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/laborRequisition", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/laborRequisition"), access: "isProjectResponsible=true" },
        Project_Detail_Daily_Entry_LaborRequisition_Edit:       { name: "Project_Detail_Daily_Entry_LaborRequisition_Edit", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/laborRequisition/edit/:id?", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/laborRequisition_edit"), access: "isProjectResponsible=true" },

        Project_Detail_Daily_Entry_Material:                    { name: "Project_Detail_Daily_Entry_Material", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/material", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/material")},
        Project_Detail_Daily_Entry_Material_Edit:               { name: "Project_Detail_Daily_Entry_Material_Edit", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/material/edit/:id?", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/material-edit")},

        Project_Detail_Daily_Entry_Production:                  { name: "Project_Detail_Daily_Entry_Production", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/production", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/production"), access: "isProjectResponsible=true" },
        Project_Detail_Daily_Entry_Production_Edit:             { name: "Project_Detail_Daily_Entry_Production_Edit", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/production/edit/:id?/:line?", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/production_edit"), access: "isProjectResponsible=true" },

        Project_Detail_Daily_Entry_BidItem:                     { name: "Project_Detail_Daily_Entry_BidItem", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/productionBidItem", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/production-bid-item"), access: "isProjectResponsible=true" },

        Project_Detail_Daily_Entry_ProjectProgress:             { name: "Project_Detail_Daily_Entry_ProjectProgress", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/projectProgress", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/projectProgress"), access: "isProjectResponsible=true" },
        Project_Detail_Daily_Entry_ProjectProgress_Edit:        { name: "Project_Detail_Daily_Entry_ProjectProgress_Edit", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/projectProgress/edit/:id", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/projectProgress_edit"), access: "isProjectResponsible=true" },

        Project_Detail_Daily_Entry_SkillCertificate:            { name: "Project_Detail_Daily_Entry_SkillCertificate", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/skillCertificate", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/skillCertificate"), access: "isProjectResponsible=true" },

        Project_Detail_Daily_Entry_Subcontractor:               { name: "Project_Detail_Daily_Entry_Subcontractor", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/subcontractor", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/subcontractor"), access: "isProjectResponsible=true" },
        Project_Detail_Daily_Entry_Subcontractor_Edit:          { name: "Project_Detail_Daily_Entry_Subcontractor_Edit", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/subcontractor/edit/:type/:id?", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/subcontractor_edit"), access: "isProjectResponsible=true" },
        Project_Detail_Daily_Entry_BreakEntry:                  { name: "Project_Detail_Daily_Entry_BreakEntry", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/break", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/breakEntry") },
        Project_Detail_Daily_Entry_BreakEntry_Edit:             { name: "Project_Detail_Daily_Entry_BreakEntry_Edit", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/break/edit/:dispatchId", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/breakEntry_edit") },

        Project_Detail_Daily_Entry_TimeEntry:                   { name: "Project_Detail_Daily_Entry_TimeEntry",  route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/timeEntry", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/timeEntry") },
        Project_Detail_Daily_Entry_TimeEntry_Edit:              { name: "Project_Detail_Daily_Entry_TimeEntry_Edit", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/timeEntry/edit/:id?", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/timeEntry_edit") },
        Project_Detail_Daily_Entry_TimeEntry_Bonus:             { name: "Project_Detail_Daily_Entry_TimeEntry_Bonus", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/timeEntry/bonus/:id?", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/timeEntry_bonus") },
        Project_Detail_Daily_Entry_TimeEntryEmployee:           { name: "Project_Detail_Daily_Entry_TimeEntryEmployee", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/timeEntry/dispatch/:dispatchId", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/timeEntryEmployee") },
        Project_Detail_Daily_Entry_Add_Link_Equipmnent:         { name: "Project_Detail_Daily_Entry_Add_Link_Equipmnent", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/link-equipment/add/:id?", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/timeEntry_add_link_equipment") },

        Project_Detail_Daily_Entry_Summary:                     { name: "Project_Detail_Daily_Entry_Summary", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/dailyEntrySummary", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/dailyEntrySummary") },

        Project_Detail_Daily_Entry_Manage_Lunch_Breaks:         { name: "Project_Detail_Daily_Entry_Manage_Lunch_Breaks", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/manageLunchAndBreaks", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/manageLunchAndBreaks") },

        Project_Detail_Daily_Entry_EquipmentCounters: { name: "Project_Detail_Daily_Entry_EquipmentCounters", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/equipmentCounters", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/equipmentCounters") },

        Project_Detail_Daily_Entry_TaskProgress: { name: "Project_Detail_Daily_Entry_TaskProgress", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/task-progress", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/task-progress"), access: "isProjectResponsible=true" },

        Project_MaterialOrder: { name: "Project_Detail_Daily_Entry_MaterialOrders", route: "project/:dispatchProjectCode/materialOrder", moduleId: PLATFORM.moduleName("pages/projects/material-order/materialOrder") },
        Project_MaterialOrder_edit: { name: "Project_Detail_Daily_Entry_MaterialOrder", route: "project/:dispatchProjectCode/materialOrder/edit/:orderId?", moduleId: PLATFORM.moduleName("pages/projects/material-order/materialOrder_edit") },
        Project_MaterialOrder_summary: { name: "Project_Detail_Daily_Entry_MaterialOrder_Summary", route: "project/:dispatchProjectCode/materialOrder/edit/summary/:orderId?", moduleId: PLATFORM.moduleName("pages/projects/material-order/materialOrder_summary") },
        Project_MaterialOrder_summary_item: { name: "Project_Detail_Daily_Entry_MaterialOrder", route: "project/:dispatchProjectCode/materialOrder/edit/summary/:orderId/item/:orderDate/:lineNo?", moduleId: PLATFORM.moduleName("pages/projects/material-order/material-order-item") },

        Project_MaterialOrder_item: { name: "Project_Detail_Daily_Entry_MaterialOrder", route: "project/:dispatchProjectCode/materialOrder/edit/:orderId/item/:orderDate/:lineNo?", moduleId: PLATFORM.moduleName("pages/projects/material-order/material-order-item") },
        Project_MaterialOrder_document:      { name: "Project_Detail_Daily_Entry_MaterialOrder_document",     route: "project/:dispatchProjectCode/materialOrder/edit/:orderId/document",    moduleId: PLATFORM.moduleName("pages/projects/material-order/material-order-document") },
        Project_MaterialOrder_document_add:  { name: "Project_Detail_Daily_Entry_MaterialOrder_document_add", route: "project/:dispatchProjectCode/materialOrder/edit/:orderId/document/add", moduleId: PLATFORM.moduleName("pages/projects/material-order/material-order-document_add") },

        Project_MaterialOrder_memo:  { name: "Receipt_Of_Goods_Memo", route: "project/:dispatchProjectCode/materialOrder/edit/:orderId/memo", moduleId: PLATFORM.moduleName("pages/projects/material-order/memo") },
        Project_MaterialOrder_AdditionalFields:  { name: "Project_Detail_MaterialOrder_AdditionalFields", route: "project/:dispatchProjectCode/materialOrder/edit/:orderId/additionalFields", moduleId: PLATFORM.moduleName("pages/projects/material-order/additionalFields") },

        Project_Detail_Daily_Entry_AdditionalFields: { name: "Project_Detail_Daily_Entry_AdditionalFields", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/additionalFields", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/additionalFields"), access: "isProjectResponsible=true" },

        Project_Detail_Daily_Entry_MaterialOrder: { name: "Project_Detail_Daily_Entry_MaterialOrders", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/materialOrder", moduleId: PLATFORM.moduleName("pages/projects/material-order/materialOrder") },
        Project_Detail_Daily_Entry_MaterialOrder2: { name: "Project_Detail_Daily_Entry_MaterialOrder", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/materialOrder/edit/:orderId?", moduleId: PLATFORM.moduleName("pages/projects/material-order/materialOrder_edit") },
        Project_Detail_Daily_Entry_MaterialOrder3: { name: "Project_Detail_Daily_Entry_MaterialOrder", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/materialOrder/edit/:orderId/item/:orderDate/:lineNo?", moduleId: PLATFORM.moduleName("pages/projects/material-order/material-order-item") },

        Project_Detail_Daily_Entry_MaterialOrder_document:      { name: "Project_Detail_Daily_Entry_MaterialOrder_document",     route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/materialOrder/edit/:orderId/document",    moduleId: PLATFORM.moduleName("pages/projects/material-order/material-order-document") },
        Project_Detail_Daily_Entry_MaterialOrder_document_add:  { name: "Project_Detail_Daily_Entry_MaterialOrder_document_add", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/materialOrder/edit/:orderId/document/add", moduleId: PLATFORM.moduleName("pages/projects/material-order/material-order-document_add") },

        Project_Detail_Daily_Entry_MaterialOrder_memo: { name: "Receipt_Of_Goods_Memo", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/materialOrder/edit/:orderId/memo", moduleId: PLATFORM.moduleName("pages/projects/material-order/memo") },
        Project_Detail_Daily_Entry_MaterialOrder_AdditionalFields: { name: "Project_Detail_Daily_Entry_MaterialOrder_AdditionalFields", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/materialOrder/edit/:orderId/additionalFields", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/material-order/additionalFields") },

        Project_Daily_Entry_Cancel_Attendances: { name: "Project_Daily_Entry_Cancel_Attendances", route: "project/:dispatchProjectCode/dailyEntry/:dailyEntryDate/cancel-attendances", moduleId: PLATFORM.moduleName("pages/projects/dailyEntry/attendances_cancel") },

        // Service
        Service_Add:                           { name: "Service_Add", route: "service/add", moduleId: PLATFORM.moduleName("pages/services/entry_add") },
        Service_Add_Wo:                        { name: "Service_Add_Wo", route: "service/addWo", moduleId: PLATFORM.moduleName("pages/services/entry-add-wo") },

        Service_Detail:                        { name: "Service_Detail", route: "service/:serviceType/:dispatchId", moduleId: PLATFORM.moduleName("pages/services/detail") },

        Service_Detail_Action_Refuse:          { name: "Service_Detail_Action_Refuse", route: "service/:serviceType/:dispatchId/action/refuse", moduleId: PLATFORM.moduleName("pages/services/detail_refuse") },
        Service_Detail_Action_Road:            { name: "Service_Detail_Action_Road", route: "service/:serviceType/:dispatchId/action/road/:dateFrom/:dateTo/:increment", moduleId: PLATFORM.moduleName("pages/services/detail_hours_edit") },
        Service_Detail_Action_Start:           { name: "Service_Detail_Action_Start", route: "service/:serviceType/:dispatchId/action/start/:dateFrom/:dateTo/:increment", moduleId: PLATFORM.moduleName("pages/services/detail_hours_edit") },
        Service_Detail_Action_Complete:        { name: "Service_Detail_Action_Complete", route: "service/:serviceType/:dispatchId/action/complete/:assignedDate/:dateFrom/:increment", moduleId: PLATFORM.moduleName("pages/services/complete"), access: "readonly=false" },

        Service_Detail_Summary:                { name: "Service_Detail_Summary", route: "service/:serviceType/:dispatchId/summary", moduleId: PLATFORM.moduleName("pages/services/summary") },
        Service_Detail_Comment:                { name: "Service_Detail_Comment", route: "service/:serviceType/:dispatchId/comment", moduleId: PLATFORM.moduleName("pages/services/comment") },
        Service_Detail_History:                { name: "Service_Detail_History", route: "service/:serviceType/:dispatchId/history", moduleId: PLATFORM.moduleName("pages/services/history") },
        Service_Detail_History_Detail:         { name: "Service_Detail_History_Detail", route: "service/:serviceType/:dispatchId/history/:id", moduleId: PLATFORM.moduleName("pages/services/summary") },
        Service_Detail_Information:            { name: "Service_Detail_Information", route: "service/:serviceType/:dispatchId/information", moduleId: PLATFORM.moduleName("pages/services/information") },
        Service_Detail_Work_Description:       { name: "Service_Detail_Work_Description", route: "service/:serviceType/:dispatchId/work-description", moduleId: PLATFORM.moduleName("pages/services/work-description") },
        Service_Detail_Technical_Details:      { name: "Service_Detail_Technical_Details", route: "service/:serviceType/:dispatchId/technical-details", moduleId: PLATFORM.moduleName("pages/services/service-call-technical-details") },

        Service_Detail_Document:               { name: "Service_Detail_Document", route: "service/:serviceType/:dispatchId/document", moduleId: PLATFORM.moduleName("pages/services/document") },
        Service_Detail_Document_Add:           { name: "Service_Detail_Document_Add", route: "service/:serviceType/:dispatchId/document/add", moduleId: PLATFORM.moduleName("pages/services/document_add") },

        Service_Detail_Notes:                  { name: "Service_Detail_Notes", route: "service/:serviceType/:dispatchId/note", moduleId: PLATFORM.moduleName("pages/services/note") },
        Service_Detail_Notes_Edit:             { name: "Service_Detail_Notes_Edit", route: "service/:serviceType/:dispatchId/note/edit/:rowNumber?", moduleId: PLATFORM.moduleName("pages/services/note_edit") },

        Service_Detail_WorkTask: { name: "Service_Detail_WorkTask", route: "service/:serviceType/:id/workTask", moduleId: PLATFORM.moduleName("pages/services/work-task-index") },
        Service_Detail_WorkTask_Add: { name: "Service_Detail_WorkTask_Add", route: "service/:serviceType/:id/workTask/edit", moduleId: PLATFORM.moduleName("pages/services/work-task-edit") },
        Service_Detail_WorkTask_Edit: { name: "Service_Detail_WorkTask_Edit", route: "service/:serviceType/:id/workTask/edit/:transactionNumber", moduleId: PLATFORM.moduleName("pages/services/work-task-edit") },
        Service_Detail_WorkTask_Documents_Add: { name: "Service_Detail_WorkTask_Documents_Add", route: "service/:serviceType/:id/workTask/edit/:transactionNumber/document/add", moduleId: PLATFORM.moduleName("pages/services/work-task-edit-documents-add") },

        Service_Detail_Equipment:              { name: "Service_Detail_Equipment", route: "service/:serviceType/:dispatchId/equipment", moduleId: PLATFORM.moduleName("pages/services/equipment") },
        Service_Detail_EquipmentMaintenance:   { name: "Service_Detail_Equipment_Maintenance", route: "service/:serviceType/:dispatchId/equipment/maintenance", moduleId: PLATFORM.moduleName("pages/services/equipment-maintenance") },
        Service_Detail_EquipmentMaintenanceMaterialSummary:   { name: "Service_Detail_Equipment_Maintenance_Material_Summary", route: "service/:serviceType/:dispatchId/equipment/maintenance/material-summary", moduleId: PLATFORM.moduleName("pages/services/material-summary") },
        Service_Detail_EquipmentMaintenanceRequiredMaterial: { name: "Service_Detail_Equipment_Maintenance_Required_Material", route: "service/:serviceType/:dispatchId/equipment/maintenance/required/material", moduleId: PLATFORM.moduleName("pages/services/equipment-maintenance-required-material") },
        Service_Detail_EquipmentMaintenanceTask: { name: "Service_Detail_Equipment_Maintenance_Task", route: "service/:serviceType/:dispatchId/equipment/maintenance/task", moduleId: PLATFORM.moduleName("pages/services/equipment-maintenance-task") },
        Service_Detail_Equipment_Add:          { name: "Service_Detail_Equipment_Add", route: "service/:serviceType/:dispatchId/equipment/:equipmentId/add", moduleId: PLATFORM.moduleName("pages/services/equipment-edit") },
        Service_Detail_Equipment_Edit:         { name: "Service_Detail_Equipment_Edit", route: "service/:serviceType/:dispatchId/equipment/:equipmentId/edit", moduleId: PLATFORM.moduleName("pages/services/equipment-edit") },
        Service_Detail_Equipment_Replace:      { name: "Service_Detail_Equipment_Replace", route: "service/:serviceType/:dispatchId/equipment/:equipmentId/replace", moduleId: PLATFORM.moduleName("pages/services/equipment-edit") },
        Service_Detail_Equipment_Details:      { name: "Service_Detail_Equipment_Details", route: "service/:serviceType/:dispatchId/equipment/:equipmentId", moduleId: PLATFORM.moduleName("pages/services/equipment-details") },

        Service_Detail_Equipment_Document:     { name: "Service_Detail_Equipment_Document", route: "service/:serviceType/:dispatchId/equipment/:equipmentCode/document", moduleId: PLATFORM.moduleName("pages/services/equipment_document") },
        Service_Detail_Equipment_Document_Add: { name: "Service_Detail_Equipment_Document_Add", route: "service/:serviceType/:dispatchId/equipment/:equipmentCode/document/add", moduleId: PLATFORM.moduleName("pages/services/equipment_document_add") },

        // TODO ML: Plusieurs fois le même nom!
        Service_Detail_MaterialRequisition:    { name: "Service_Detail_MaterialRequisition", route: "service/:serviceType/:dispatchId/materialRequisition", moduleId: PLATFORM.moduleName("pages/services/materialRequisition") },
        Service_Detail_MaterialRequisition2:   { name: "Service_Detail_MaterialRequisition", route: "service/:serviceType/:dispatchId/materialRequisition/edit/:requisitionId?", moduleId: PLATFORM.moduleName("pages/services/materialRequisition_edit") },
        Service_Detail_MaterialRequisition3:   { name: "Service_Detail_MaterialRequisition", route: "service/:serviceType/:dispatchId/materialRequisition/edit/:requisitionId/item/:requisitionDate/:lineNo?", moduleId: PLATFORM.moduleName("pages/services/material-requisition-item") },
        // TODO ML: Plusieurs fois le même nom!
        Service_Detail_Contact:                { name: "Service_Detail_Contact", route: "service/:serviceType/:dispatchId/contact", moduleId: PLATFORM.moduleName("pages/services/contact") },
        Service_Detail_Contact2:               { name: "Service_Detail_Contact", route: "service/:serviceType/:dispatchId/contact/detail/:id", moduleId: PLATFORM.moduleName("pages/services/contact_detail") },

        Service_Detail_Labor:                  { name: "Service_Detail_Labor", route: "service/:serviceType/:dispatchId/labor", moduleId: PLATFORM.moduleName("pages/services/labor") },
        Service_Detail_Labor_Edit:             { name: "Service_Detail_Labor_Edit", route: "service/:serviceType/:dispatchId/labor/edit/:lineNo?", moduleId: PLATFORM.moduleName("pages/services/labor_edit") },

        Service_Detail_Material:               { name: "Service_Detail_Material", route: "service/:serviceType/:dispatchId/material", moduleId: PLATFORM.moduleName("pages/services/material") },
        Service_Detail_Material_List:          { name: "Service_Detail_Material_List", route: "service/:serviceType/:dispatchId/material-list", moduleId: PLATFORM.moduleName("pages/services/material-list") },
        Service_Detail_Material_Edit:          { name: "Service_Detail_Material_Edit", route: "service/:serviceType/:dispatchId/material/edit/:isBilling/:lineNo?", moduleId: PLATFORM.moduleName("pages/services/material-edit") },

        Service_Detail_Recommendation:         { name: "Service_Detail_Recommendation", route: "service/:serviceType/:dispatchId/recommendation", moduleId: PLATFORM.moduleName("pages/services/recommendation") },
        Service_Detail_Recommendation_Edit:    { name: "Service_Detail_Recommendation_Edit", route: "service/:serviceType/:dispatchId/recommendation/edit/:recommendationId?", moduleId: PLATFORM.moduleName("pages/services/recommendation_edit") },
        Service_Detail_Recommendation_Edit_Documents_Add: { name: "Service_Detail_Recommendation_Edit_Documents_Add", route: "service/:serviceType/:dispatchId/recommendation/edit/:recommendationId/documents/add", moduleId: PLATFORM.moduleName("pages/services/recommendation_edit_document_add") },

        Service_Detail_AdditionalFields:       { name: "Service_Detail_AdditionalFields", route: "service/:serviceType/:dispatchId/additionalFields", moduleId: PLATFORM.moduleName("pages/services/additionalFields") },
        Service_Detail_AdditionalFieldsType: { name: "Service_Detail_AdditionalFields", route: "service/:serviceType/:dispatchId/additionalFieldsType", moduleId: PLATFORM.moduleName("pages/services/additionalFieldsType") },
        Service_Detail_AdditionalServiceCall: { name: "Service_Detail_AdditionalFields", route: "service/:serviceType/:dispatchId/additionalFieldsServiceCall", moduleId: PLATFORM.moduleName("pages/services/additionalFieldsServiceCall") },
        Service_Detail_AdditionalServiceCallContract: { name: "Service_Detail_AdditionalFields", route: "service/:serviceType/:dispatchId/additionalFieldsServiceCallContract", moduleId: PLATFORM.moduleName("pages/services/additionalFieldsServiceCallContract") },

        Service_Detail_SkillCertificate:       { name: "Service_Detail_SkillCertificate", route: "service/:serviceType/:dispatchId/skillCertificate", moduleId: PLATFORM.moduleName("pages/services/skillCertificate") },

        Service_Assignation:                   { name: "Service_Assignation", route: "service/:serviceType/:dispatchId/assignation", moduleId: PLATFORM.moduleName("pages/services/assignation") },

        Service_Detail_sameAddressCalls:       { name: "Service_Detail_sameAddressCalls", route: "service/:serviceType/:dispatchId/sameAddressCalls", moduleId: PLATFORM.moduleName("pages/services/sameAddressCalls") },

        Service_Detail_MaterialOrder:          { name: "Service_Detail_MaterialOrder", route: "service/:serviceType/:dispatchId/materialOrder", moduleId: PLATFORM.moduleName("pages/services/material-order/materialOrder") },
        Service_Detail_MaterialOrder2:         { name: "Service_Detail_MaterialOrder", route: "service/:serviceType/:dispatchId/materialOrder/edit/:orderId?", moduleId: PLATFORM.moduleName("pages/services/material-order/materialOrder_edit") },
        Service_Detail_MaterialOrder3:         { name: "Service_Detail_MaterialOrder", route: "service/:serviceType/:dispatchId/materialOrder/edit/:orderId/item/:orderDate/:lineNo?", moduleId: PLATFORM.moduleName("pages/services/material-order/material-order-item") },
        Service_Detail_MaterialOrderSummary:   { name: "Service_MaterialOrder_Summary", route: "service/:serviceType/:dispatchId/materialOrder/edit/summary/:orderId?", moduleId: PLATFORM.moduleName("pages/services/material-order/materialOrder_summary") },
        Service_Detail_MaterialOrderSummary_Item: { name: "Service_Detail_MaterialOrder", route: "service/:serviceType/:dispatchId/materialOrder/edit/summary/:orderId/item/:orderDate/:lineNo?", moduleId: PLATFORM.moduleName("pages/services/material-order/material-order-item") },

        Service_Detail_MaterialOrder_document:      { name: "Service_Detail_MaterialOrder_document",     route: "service/:serviceType/:dispatchId/materialOrder/edit/:orderId/document/",    moduleId: PLATFORM.moduleName("pages/services/material-order/material-order-document") },
        Service_Detail_MaterialOrder_document_add: { name: "Service_Detail_MaterialOrder_document_add", route: "service/:serviceType/:dispatchId/materialOrder/edit/:orderId/document/add", moduleId: PLATFORM.moduleName("pages/services/material-order/material-order-document_add") },
        Service_Detail_MaterialOrder_memo: { name: "Receipt_Of_Goods_Memo", route: "service/:serviceType/:dispatchId/materialOrder/edit/:orderId/memo", moduleId: PLATFORM.moduleName("pages/services/material-order/memo") },
        Service_Detail_MaterialOrder_AdditionalFields:          { name: "Service_Detail_MaterialOrder_AdditionalFields", route: "service/:serviceType/:dispatchId/materialOrder/edit/:orderId/additionalFields", moduleId: PLATFORM.moduleName("pages/services/material-order/additionalFields") },

        Service_Maintenance_Tasks:             { name: "Service_Maintenance_Tasks", route: "service/:serviceType/:dispatchId/maintenance-tasks", moduleId: PLATFORM.moduleName("pages/services/maintenance-tasks") },
        Service_Maintenance_Measures:             { name: "Service_Maintenance_Measures", route: "service/:serviceType/:dispatchId/maintenance-measures", moduleId: PLATFORM.moduleName("pages/services/maintenance-measures") },
        Service_Call_Inspections:             { name: "Service_Call_Inspections", route: "service/:serviceType/:dispatchId/inspection", moduleId: PLATFORM.moduleName("pages/inspections/service-call/index") },
        Service_Call_Inspections_Add:             { name: "Service_Call_Inspections_Add", route: "service/:serviceType/:dispatchId/inspection/add", moduleId: PLATFORM.moduleName("pages/inspections/service-call/add") },
        Service_Call_Inspections_Info:             { name: "Service_Call_Inspections_Info", route: "service/:serviceType/:dispatchId/inspection/info/:inspectionId", moduleId: PLATFORM.moduleName("pages/inspections/info") },
        Service_Call_Inspections_Details:             { name: "Service_Call_Inspections_Details", route: "service/:serviceType/:dispatchId/inspection/info/:inspectionId/details", moduleId: PLATFORM.moduleName("pages/inspections/details") },

        // Service Quotations
        Service_Quotations_Desktop:                 { name: "Service_Quotations_Desktop",               route: "d/service/quotations",                                                 moduleId: PLATFORM.moduleName("pages/services/quotations/list-full") },
        Service_Quotations_Mobile:                  { name: "Service_Quotations_Mobile",                route: "m/service/quotations",                                                 moduleId: PLATFORM.moduleName("pages/services/quotations/list-light") },
        Service_Quotation_Desktop:                  { name: "Service_Quotation_Desktop",                route: "d/service/quotations/:quotationId",                                    moduleId: PLATFORM.moduleName("pages/services/quotations/quotation-full") },
        Service_Quotation_Mobile:                   { name: "Service_Quotation_Mobile",                 route: "m/service/quotations/:quotationId",                                    moduleId: PLATFORM.moduleName("pages/services/quotations/quotation-light") },
        Service_Quotation_Details:                  { name: "Service_Quotation_Details",                route: "m/service/quotations/:quotationId/details",                            moduleId: PLATFORM.moduleName("pages/services/quotations/details") },
        Service_Quotation_Items:                    { name: "Service_Quotation_Items",                  route: "m/service/quotations/:quotationId/items",                              moduleId: PLATFORM.moduleName("pages/services/quotations/items") },
        Service_Quotation_Item_Material:            { name: "Service_Quotation_Item_Material",          route: "m/service/quotations/:quotationId/items/material/:itemId?",            moduleId: PLATFORM.moduleName("pages/services/quotations/item-material") },
        Service_Quotation_Item_Bonus:               { name: "Service_Quotation_Item_Bonus",             route: "m/service/quotations/:quotationId/items/bonus/:itemId?",               moduleId: PLATFORM.moduleName("pages/services/quotations/item-bonus") },
        Service_Quotation_Item_Labor:               { name: "Service_Quotation_Item_Labor",             route: "m/service/quotations/:quotationId/items/labor/:itemId?",               moduleId: PLATFORM.moduleName("pages/services/quotations/item-labor") },
        Service_Quotation_Item_Equipment:           { name: "Service_Quotation_Item_Equipment",         route: "m/service/quotations/:quotationId/items/equipment/:itemId?",           moduleId: PLATFORM.moduleName("pages/services/quotations/item-equipment") },
        Service_Quotation_Item_BillingItem:         { name: "Service_Quotation_Item_BillingItem",       route: "m/service/quotations/:quotationId/items/billing-item/:itemId?",        moduleId: PLATFORM.moduleName("pages/services/quotations/item-material") },
        Service_Quotation_Item_MiscChargeItem:      { name: "Service_Quotation_Item_MiscChargeItem",    route: "m/service/quotations/:quotationId/items/misc-charge-item/:itemId?",    moduleId: PLATFORM.moduleName("pages/services/quotations/item-material") },
        Service_Quotation_Equipments:               { name: "Service_Quotation_Equipments",             route: "m/service/quotations/:quotationId/equipments",                         moduleId: PLATFORM.moduleName("pages/services/quotations/equipments") },
        Service_Quotation_Documents:                { name: "Service_Quotation_Documents",              route: "m/service/quotations/:quotationId/documents",                          moduleId: PLATFORM.moduleName("pages/services/quotations/documents") },
        Service_Quotation_Documents_Add:            { name: "Service_Quotation_Documents_Add",          route: "m/service/quotations/:quotationId/documents/add",                      moduleId: PLATFORM.moduleName("pages/services/quotations/documents-add") },
        Service_Quotation_Summary:                  { name: "Service_Quotation_Summary",                route: "m/service/quotations/:quotationId/summary",                            moduleId: PLATFORM.moduleName("pages/services/quotations/summary") },
        Service_Quotation_PricingType:              { name: "Service_Quotation_PricingType",            route: "m/service/quotations/:quotationId/pricing-type",                       moduleId: PLATFORM.moduleName("pages/services/quotations/pricing-type") },
        Service_Quotation_Refusal:                  { name: "Service_Quotation_Refusal",                route: "m/service/quotations/:quotationId/refusal",                            moduleId: PLATFORM.moduleName("pages/services/quotations/refusal") },
        Service_Quotation_Generate_Service_Call:    { name: "Service_Quotation_Generate_Service_Call", route: "m/quotation/service/add", moduleId: PLATFORM.moduleName("pages/services/quotations/generate-service-call") },
        Service_Quotation_Generate_Work_Order:      { name: "Service_Quotation_Generate_Work_Order",   route: "m/quotation/workorder/add", moduleId: PLATFORM.moduleName("pages/services/quotations/generate-service-call") },
        Service_Quotation_Single_Desktop:           { name: "Service_Quotation_Single_Desktop", route: "d/service/quotations/:quotationId", moduleId: PLATFORM.moduleName("pages/services/quotations/quotation-full") },
        Service_Quotation_InternalNotes: {
            name: "Service_Quotation_InternalNotes",
            route: "m/service/quotations/:quotationId/internal-notes",
            moduleId: PLATFORM.moduleName("pages/services/quotations/internal-notes")
        },
        Service_Quotation_Added_Equipments: { name: "Service_Quotation_Added_Equipments", route: "m/service/quotations/:quotationId/added-equipments", moduleId: PLATFORM.moduleName("pages/services/quotations/added-equipments") },
        Service_Quotation_Added_Equipment: { name: "Service_Quotation_Added_Equipment", route: "m/service/quotations/:quotationId/added-equipment", moduleId: PLATFORM.moduleName("pages/services/quotations/added-equipment") },

        // PROCORE
        Procore_Configuration:                      { name: "Procore_Configuration",                    route: "d/procore/configuration",                                              moduleId: PLATFORM.moduleName("pages/procore/configuration/configuration") },
        Procore_Synchronization:                    { name: "Procore_Synchronization",                  route: "d/procore/synchronization",                                            moduleId: PLATFORM.moduleName("pages/procore/synchronization/synchronization") },
        Procore_Tools:                              { name: "Procore_Tools",                            route: "d/procore/tools",                                                      moduleId: PLATFORM.moduleName("pages/procore/tools/tools") },

        // Timesheets
        Timesheet_TimeEntry:                   { name: "Timesheet_TimeEntry", route: "timesheet/edit/:timesheetId?/:timesheetEntryId?", moduleId: PLATFORM.moduleName("pages/timesheet/timesheet_timeEntry") },
        Timesheet_Summary:                     { name: "Timesheet_Summary", route: "timesheet/summary", moduleId: PLATFORM.moduleName("pages/timesheet/summary") },

        // Misc
        Recommendation_Edit:                   { name: "Recommendation_Edit", route: "recommendation/edit/:recommendationId?", moduleId: PLATFORM.moduleName("pages/recommendation_edit") },
        Recommendation_Edit_Documents_Add:     { name: "Recommendation_Edit_Documents_Add" , route: "recommendation/edit/:recommendationId/documents/add", moduleId: PLATFORM.moduleName("pages/recommendation_edit_document_add") },
        Inspection_Add:                        { name: "Inspection_Add", route: "inspections/add", moduleId: PLATFORM.moduleName("pages/inspections/add") },
        Inspection_Info:                       { name: "Inspection_Info", route: "inspection/info/:inspectionId", moduleId: PLATFORM.moduleName("pages/inspections/info") },
        Inspection_Detail:                     { name: "Inspection_Detail", route: "inspection/info/:inspectionId/details", moduleId: PLATFORM.moduleName("pages/inspections/details") },
        Inspection_Info_Documents:         { name: "Inspection_Info_Documents", route: "inspection/info/:inspectionId/document", moduleId: PLATFORM.moduleName("pages/inspections/document") },
        Inspection_Info_Documents_Add:         { name: "Inspection_Info_Documents_Add", route: "inspection/info/:inspectionId/document/add", moduleId: PLATFORM.moduleName("pages/inspections/document_add") },
        Inspection_Info_Signature:         { name: "Inspection_Info_Signature", route: "inspection/info/:inspectionId/signature", moduleId: PLATFORM.moduleName("pages/inspections/signature") },

        InventoryProjectTransfer_Add: {
            name: "InventoryProjectTransfer_Add",
            route: "inventory-project-transfer/add",
            moduleId: PLATFORM.moduleName("pages/inventory-project-transfer/add")
        },

        InventoryProjectTransfer_Info: {
            name: "InventoryProjectTransfer_Info",
            route: "inventory-project-transfer/:transferId/info",
            moduleId: PLATFORM.moduleName("pages/inventory-project-transfer/info")
        },

        InventoryProjectTransfer_Edit: {
            name: "InventoryProjectTransfer_Edit",
            route: "inventory-project-transfer/:transferId/info/edit",
            moduleId: PLATFORM.moduleName("pages/inventory-project-transfer/edit")
        },

        InventoryProjectTransfer_Edit_Details: {
            name: "InventoryProjectTransfer_Edit_Details",
            route: "inventory-project-transfer/:transferId/info/details",
            moduleId: PLATFORM.moduleName("pages/inventory-project-transfer/edit-details")
        },
        InventoryProjectTransfer_Memo: {
            name: "InventoryProjectTransfer_Memo",
            route: "inventory-project-transfer/:transferId/info/memo",
            moduleId: PLATFORM.moduleName("pages/inventory-project-transfer/memo")
        },

        InventoryProjectTransfer_Documents: {
            name: "InventoryProjectTransfer_Documents",
            route: "inventory-project-transfer/:transferId/info/document",
            moduleId: PLATFORM.moduleName("pages/inventory-project-transfer/documents")
        },

        InventoryProjectTransfer_Documents_Add: {
            name: "InventoryProjectTransfer_Documents_Add",
            route: "inventory-project-transfer/:transferId/info/document/add",
            moduleId: PLATFORM.moduleName("pages/inventory-project-transfer/documents-add")
        },

        InventoryProjectTransfer_Edit_Details_Item_Add: {
            name: "InventoryProjectTransfer_Edit_Details_Add_Item",
            route: "inventory-project-transfer/:transferId/info/details/add",
            moduleId: PLATFORM.moduleName("pages/inventory-project-transfer/edit-details-item")
        },

        InventoryProjectTransfer_Edit_Details_Item_Add_Scanner: {
            name: "InventoryProjectTransfer_Edit_Details_Item_Add_Scanner",
            route: "inventory-project-transfer/:transferId/info/details/scanner",
            moduleId: PLATFORM.moduleName("pages/inventory-project-transfer/edit-details-item-scanner")
        },

        InventoryProjectTransfer_Edit_Details_Item_Edit: {
            name: "InventoryProjectTransfer_Edit_Details_Edit_Item",
            route: "inventory-project-transfer/:transferId/info/details/edit",
            moduleId: PLATFORM.moduleName("pages/inventory-project-transfer/edit-details-item")
        },

        InventorySiteTransfer_Info: {
            name: "InventorySiteTransfer_Info",
            route: "inventory-site-transfer/:transferId/info",
            moduleId: PLATFORM.moduleName("pages/inventory-site-transfer/info")
        },

        InventorySiteTransfer_Add: {
            name: "InventorySiteTransfer_Add",
            route: "inventory-site-transfer/edit",
            moduleId: PLATFORM.moduleName("pages/inventory-site-transfer/edit")
        },

        InventorySiteTransfer_Edit: {
            name: "InventorySiteTransfer_Edit",
            route: "inventory-site-transfer/edit",
            moduleId: PLATFORM.moduleName("pages/inventory-site-transfer/edit")
        },

        InventorySiteTransfer_Items: {
            name: "InventorySiteTransfer_Items",
            route: "inventory-site-transfer/:transferId/info/items",
            moduleId: PLATFORM.moduleName("pages/inventory-site-transfer/items")
        },

        InventorySiteTransfer_Item_Add: {
            name: "InventorySiteTransfer_Items_Add",
            route: "inventory-site-transfer/:transferId/info/items/add",
            moduleId: PLATFORM.moduleName("pages/inventory-site-transfer/item-edit")
        },

        InventorySiteTransfer_Item_Edit: {
            name: "InventorySiteTransfer_Items_Edit",
            route: "inventory-site-transfer/:transferId/info/items/edit",
            moduleId: PLATFORM.moduleName("pages/inventory-site-transfer/item-edit")
        },

        InventorySiteTransfer_Items_Scanner: {
            name: "InventorySiteTransfer_Items_Scanner",
            route: "inventory-site-transfer/:transferId/info/items/items-scanner",
            moduleId: PLATFORM.moduleName("pages/inventory-site-transfer/items-scanner")
        },

        InventorySiteTransfer_Memo: {
            name: "InventorySiteTransfer_Memo",
            route: "inventory-site-transfer/:transferId/info/memo",
            moduleId: PLATFORM.moduleName("pages/inventory-site-transfer/memo")
        },

        InventorySiteTransfer_Documents: {
            name: "InventorySiteTransfer_Documents",
            route: "inventory-site-transfer/:transferId/info/document",
            moduleId: PLATFORM.moduleName("pages/inventory-site-transfer/documents")
        },

        InventorySiteTransfer_Documents_Add: {
            name: "InventorySiteTransfer_Documents_Add",
            route: "inventory-site-transfer/:transferId/info/document/add",
            moduleId: PLATFORM.moduleName("pages/inventory-site-transfer/documents-add")
        },

        Employee_Profile_Picture: {
            name: "Employee_Profile_Picture",
            route: "pages/employee-profile/picture",
            moduleId: PLATFORM.moduleName("pages/employee-profile-picture")
        },

        Approval_Save:                         { name: "Approval_Save", route: "approval/:actionId/:action", moduleId: PLATFORM.moduleName("pages/approval_save") },
        HoursWorked:                           { name: "HoursWorked", route: "hoursWorked", moduleId: PLATFORM.moduleName("pages/hoursWorked") },

        // Maestro*
        HostRedirect:                          { name: "HostRedirect", route: "hostRedirect", moduleId: PLATFORM.moduleName("pages/hostRedirect"), hideHeader: true, showBack: false },
        takeoffHome:                           { name: "takeoffHome", route: "takeoffHome", moduleId: PLATFORM.moduleName("pages/takeoff/takeoffHome"), hideHeader: true, showBack: false },
        takeoffQuantity:                       { name: "takeoffQuantity", route: "estimates/:estimateId/takeoffQuantity", moduleId: PLATFORM.moduleName("pages/takeoff/takeOffQuantity"), hideHeader: true, showBack: false },
        takeoffQuantityImport:                 { name: "takeoffQuantityImport", route: "estimates/:estimateId/takeoffQuantityImport", moduleId: PLATFORM.moduleName("pages/takeoff/takeOffQuantityImport"), hideHeader: true, showBack: false },
        takeoffQuantityImportConfig:           { name: "takeoffQuantityImportConfig", route: "estimates/:estimateId/takeoffQuantityImportConfig", moduleId: PLATFORM.moduleName("pages/takeoff/takeOffQuantityImportConfig"), hideHeader: true, showBack: false },

        BambooHrSync:                          { name: "BambooHR_Synchronization", route: "bambooHrSync", moduleId: PLATFORM.moduleName("pages/bamboo-hr/bamboo-hr-sync"), hideHeader: true, showBack: false },
        BambooHrSyncConfig:                    { name: "BambooHR_Synchronization_Config", route: "bambooHrSyncConfig", moduleId: PLATFORM.moduleName("pages/bamboo-hr/bamboo-hr-sync-config"), hideHeader: true, showBack: true },

        // JL: Routes to test error & exceptions to make sure they are handled correctly
        Error_Manager_Home:                    { name: "Error_Manager_Home", route: "errors", moduleId: PLATFORM.moduleName("pages/errors/index") },
        Error_Manager_In:                      { name: "Error_Manager_In", route: "errors/in/:type", moduleId: PLATFORM.moduleName("pages/errors/in") },
        Error_Manager_Api:                     { name: "Error_Manager_Api", route: "errors/api/:statusCode/:type", moduleId: PLATFORM.moduleName("pages/errors/api"), },

        Receipt_Of_Goods_Document: {
            name: "Receipt_Of_Goods_Document",
            route: "ReceiptOfGoods/:receiptId/document",
            moduleId: PLATFORM.moduleName("pages/receipt-of-goods/document"),
        },
        Receipt_Of_Goods_Document_Add: {
            name: "Receipt_Of_Goods_Document_Add",
            route: "ReceiptOfGoods/:receiptId/document/add",
            moduleId: PLATFORM.moduleName("pages/receipt-of-goods/document_add"),
        },
        Receipt_Of_Goods_Manage_Serial_Numbers: {
            name: "Receipt_Of_Goods_Manage_Serial_Numbers",
            route: "ReceiptOfGoods/:receiptId/manageSerialNumbers",
            moduleId: PLATFORM.moduleName("pages/receipt-of-goods/manage-serial-numbers"),
        },
        Receipt_Of_Goods_Add_Item: {
            name: "Receipt_Of_Goods_Add_Item",
            route: "ReceiptOfGoods/:receiptId/addItem",
            moduleId: PLATFORM.moduleName("pages/receipt-of-goods/add-item"),
        },
        Receipt_Of_Goods_Edit_Item: {
            name: "Receipt_Of_Goods_Edit_Item",
            route: "ReceiptOfGoods/:receiptId/editItem",
            moduleId: PLATFORM.moduleName("pages/receipt-of-goods/add-item"),
        },
        Receipt_Of_Goods_Order_Reception_Edit: {
            name: "Receipt_Of_Goods_Order_Reception_Edit",
            route: "ReceiptOfGoods/:receiptId",
            moduleId: PLATFORM.moduleName("pages/receipt-of-goods/edit")
        },

        Receipt_Of_Goods_Memo: {
            name: "Receipt_Of_Goods_Memo",
            route: "ReceiptOfGoods/:receiptId/Memo",
            moduleId: PLATFORM.moduleName("pages/receipt-of-goods/memo")
        },

        Receipt_Of_Goods_MaterialOrder_summary: { name: "Receipt_Of_Goods_MaterialOrder_Summary", route: "ReceiptOfGoods/materialOrder/edit/summary/:orderId?", moduleId: PLATFORM.moduleName("pages/receipt-of-goods/materialOrder_summary") },

        TimeKeeping_Edit: {
            name: "TimeKeeping_Edit",
            route: "TimeKeeping/:punchId",
            moduleId: PLATFORM.moduleName("pages/timekeeping/edit")
        },

        Calendar_AddUnavailability: {
            name: "Calendar_Employee_AddNonAvailability",
            route: "calendar/add-unavailability",
            moduleId: PLATFORM.moduleName("pages/addUnavailability")
        }
    };

    public mainRoutes: SpecificRouteConfig[] = [];

    constructor() {
        this.mainRoutes = Object.keys(this.routes).map((key: string, index: number) => this.routes[key]);

        // TODO: Uncomment when duplicate route names have been fixed
        /*
        const duplicateRoute =
            _.chain(this.mainRoutes)
                .countBy(x => x.name)
                .map((value, key) => { return { name: key, count: value }; })
                .find(x => x.count > 1)
                .value();

        Assert.isTrue(duplicateRoute === undefined, `Duplicate route name: ${duplicateRoute.name}.`);
        */

        _.each(this.mainRoutes, (route: SpecificRouteConfig) => {
            if (route.activationStrategy === undefined) {
                route.activationStrategy = activationStrategy.replace;
            }
            return route;
        });
    }

    public getRoute(route: string): SpecificRouteConfig | null {
        const result = this.mainRoutes.find((r: SpecificRouteConfig) => {
            return r.route === route || _.contains(r.route, route);
        });

        return result ? result : null;
    }

    public getRouteByName(routeName: string): string | string[] | null {
        const route = this.mainRoutes.find((r: SpecificRouteConfig) => r.name === routeName );

        return route ? route.route : null;
    }

    public allRoutes(): SpecificRouteConfig[] {
        return this.mainRoutes;
    }
}
