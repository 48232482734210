import val from "core/val";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import dispatchService from "services/dispatchService";
import enumHelper from "helpers/enumHelper";
import routerHelper from "helpers/routerHelper";

@autoinject
export class FieldServiceAttendanceAbsence {

    public workOrderId: number = 0;
    public dispatchId: number = 0;
    public reason: string = "";

    constructor(private readonly i18n: I18N) {}

    public activate(params: any): any {
        this.workOrderId = params.workOrderId;
        this.dispatchId = params.dispatchId;
    }

    public setReason(): void {
        dispatchService.setWorkorderAttendanceStatus(this.workOrderId, this.dispatchId, { Presence: enumHelper.attendance().ABSENT, AbsenceReason: this.reason })
            .done(routerHelper.navigateBack);
    }

}
