import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";
import settingRepository from "repositories/settingRepository";
import documentService from "services/documentService";

@autoinject
export class RecommendationEditDocumentAdd {

    public readonly: boolean = false;
    public subTitle: string = "";
    public editId: any;
    public option: string = "";
    public contextPrefix: string = "";
    public actions!: { save: any; };

    public activate(params: any): void {
        const queryStringParams = routerHelper.getQuerystring(params.q);
        this.readonly = queryStringHelper.parseReadonly(params.q);
        this.subTitle = decodeURIComponent(queryStringParams.subTitle);
        this.editId = params.editId;
        this.option = params.option;
        this.contextPrefix = params.contextPrefix;

        const dispatchTemplateId = settingRepository.getDispatchTemplate();

        this.actions = {
            save: documentService.saveRecommendationPicture.bind(this, params.recommendationId, "recommendation", this.editId, dispatchTemplateId)
        };
    }

}
