import routerHelper from "helpers/routerHelper";
import documentService from "services/documentService";
import { autoinject } from "aurelia-framework";
import { SettingRepository } from "repositories/setting-repository";

@autoinject
export class FieldServiceDocumentAdd {
    public readonly: boolean = false;
    public workOrderId: string = "";
    public actions: any;
 
    constructor(
        private readonly settingRepository: SettingRepository
    ) { }

    public activate(params: any): void {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.workOrderId = params.workOrderId;
        this.actions = { save: documentService.saveWorkOrderPicture.bind(self, this.workOrderId, this.settingRepository.getDispatchTemplate()) };
    }
}
