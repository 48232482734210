import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";
import materialRequisitionService from "services/materialRequisitionService";

@autoinject()
export class FieldServiceMaterialRequisition {
    public workOrderId: string = "";
    public isProjectResponsible: boolean = false;
    public readonly: boolean = false;
    public actions: any;

    public activate(params: any): any {
        this.workOrderId = params.workOrderId;

        this.actions = {
            getRequisitions: materialRequisitionService.getMaterialRequisitionsForFieldService.bind(this, this.workOrderId)
        };

        const paramsQs = routerHelper.getQuerystring(params.q);
        this.isProjectResponsible = (paramsQs && paramsQs.isProjectResponsible === "true") || false;
        if (paramsQs && paramsQs.readonly) {
            this.readonly = queryStringHelper.parseReadonly(params.q);
        }
    }
}
