import { BindingEngine, observable, bindable, inject } from "aurelia-framework";

import { default as _ } from "underscore";
import { default as resx } from "core/resx";
import { default as moment } from "moment";

import { default as ProjectTimeEntryHelper } from "helpers/project-time-entry-helper";
import { TimeListHelper } from "helpers/time-list-helper"
import { default as settingHelper } from "helpers/settingHelper";
import { default as enumHelper } from "helpers/enumHelper";
import { default as dateHelper } from "helpers/dateHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as wageSettingHelper } from "helpers/wageSettingHelper";
import { default as notificationHelper } from "helpers/notificationHelper";

import { default as commonService } from "services/commonService";
import { default as tradesService } from "services/tradesService";
import { default as projectService } from "services/projectService";
import { default as templateService } from "services/templateService";
import { default as dispatchTimesheetService } from "services/dispatchTimesheetService";
import { BonusService } from "services/bonus-service";
import { ProjectConfigService } from "services/project-config-service";
import { DispatchProjectTimeEntryService } from "services/dispatch-project-time-entry-service";
import { DefaultBonusApplyOption } from "api/enums/default-bonus-apply-option";
import { BonusType } from "api/enums/bonus-type";

function lookupActivity(self, params, success, failure) {
    if ( self.selectedSubProject ) {
        projectService.getActivitiesForProject( self.selectedSubProject.id,
            params.data.filter,
            params.data.page || 1 )
            .done( data => {
                return success( data );
            } )
            .fail( failure );
    } else {
          projectService.getActivitiesForDispatchProject( self.dispatchProjectCode,
            params.data.filter,
            params.data.page || 1 )
            .done( data => {
                return success( data );
            } )
            .fail( failure );
    }
}

async function initActivities(self){
    if (self.selectedSubProject) {
        self.activities = await projectService.getActivitiesForProject(self.selectedSubProject.id);
    } else {
        self.activities = await projectService.getActivitiesForDispatchProject(self.dispatchProjectCode);
    }
}

function lookupTrades(self, params, success, failure) {
    tradesService.getTradesForDispatch(_.first(self.selectedEntries).DispatchId, params.data.filter, params.data.page || 1)
        .done((data) => {
            self.trades = data;
            return success(_.map(data,
                x => {
                    x.Id = x.Code;
                    return x;
                }));
            })
            .fail(failure);
}

function initializeTrade(self, dispatchId)
{
    return tradesService.getTradeForDispatch(dispatchId).done( (defaultTrade) => {
        if (defaultTrade) {
            self.selectedTrade = {
                id: defaultTrade.Code,
                text: defaultTrade.Code + " - " + defaultTrade.Description,
                DefaultActivityCode: defaultTrade.DefaultActivityCode
            };
        } else {
            self.selectedTrade = "";
        }
    });
}

async function ShowDefaultBonuses(self) {
    self.defaultBonuses = [];
    self.defaultBonusesFound = false;

    if(self.applyDefaultBonus && self.selectedEntries.length > 0
        && self.totalHours > 0) {
            await GetDefaultBonuses(self);
    }
}

function GetEmployeeIds(self) {
    var employeeIds = [];

    self.selectedEntries.forEach(entry => {
        if(entry.IsEmployee) {
            employeeIds.push(entry.EmployeeNumber);
        }
    });

    return employeeIds;
}

async function GetDefaultBonuses(self) {
    var employeeIds = GetEmployeeIds(self);

    if(employeeIds.length > 0) {
        var params = {
            EmployeeIds: employeeIds,
            Project: self.dispatchProjectCode,
            Activity: self.selectedActivity === "" || self.selectedActivity === null  ? null : self.selectedActivity.id,
            Shift: self.selectedShift,
            TradeCode: employeeIds.length === 1 ? self.selectedTrade.id : null,
            HoursSimple: self.regularTime,
            HoursHalf: self.halfTime,
            HoursDouble: self.doubleTime
        };

        var data = await self.bonusService.GetDefaultBonuses(params); 
        if(data) {
            self.defaultBonusesAreEquals = data.DefaultBonusesAreEquals;

            if(self.defaultBonusesAreEquals) {
                var list = _.map(data.DefaultBonuses, value => {
                    return {
                        // Textbox or checkbox
                        value: value.Type === self.bonusType.Quantity ? value.Value : 1,
                        defaultValue: value.Value, // use to store value for a checkbox bonus type
                        code: value.Code,
                        type: value.Type,
                        description: value.Description,
                        unit: value.Unit,
                        readonly: value.ReadOnly
                    };
                });

                self.defaultBonuses = list;
            } else {
                self.defaultBonusError = resx.localize("DefaultBonusesNotEquals");
            }

            self.defaultBonusesFound = true;
        }
    }
}

function clearForm(self) {
    self.selectedActivity = "";
    self.selectedTrade = "";
    self.startTime = _.first( self.selectOptions ).text;
    self.totalHours = 0;
    self.endTime = self.startTime;
    self.selectedShift = self.shiftList.length > 0 ? _.first(self.shiftList).id : 0;
    self.selectedRegularTime = "00:00";
    self.selectedHalfTime = "00:00";
    self.selectedDoubleTime = "00:00";
    self.equipment = {};
    self.equipmentActivity = {};
    self.equipmentWorkTime = "";
    self.trades = [];
}

// TODO renommer cette function
function dataEntered(self, entry, usedWages) {

    return usedWages.map((workedWage) => {
            let data = {};

            data.TimeEntryId = -1;

            data.DispatchId = entry.DispatchId;

            if (self.selectedSubProject) {
                //Set the project code if we have it
                data.ProjectCode = self.selectedSubProject.id;
            } else {
                //Else pass the dispatchProjectCode and find the construction project server side
                data.DispatchProjectCode = self.dispatchProjectCode;
            }

            if (self.selectedActivity) {
                if (self.selectedEntries.length > 1 && !entry.IsEmployee && entry.DefaultEquipmentActivityCode) {
                    data.ActivityCode = entry.DefaultEquipmentActivityCode;
                } else {
                    data.ActivityCode = self.selectedActivity.id;
                    data.ActivityDescription = self.selectedActivity.text;
                }
            } else if (self.selectedEntries.length > 1 && entry.IsEmployee && entry.DefaultTradeActivityCode) {
                data.ActivityCode = entry.DefaultTradeActivityCode;
            } else if (self.selectedEntries.length > 1 && !entry.IsEmployee && entry.DefaultEquipmentActivityCode) {
                data.ActivityCode = entry.DefaultEquipmentActivityCode;
            }

            if (self.selectedEntries.length < 2 && entry.IsEmployee && self.selectedTrade) {
                data.TradeCode = self.selectedTrade.id;
                data.TradeDescription = self.selectedTrade.text;
            } else if (entry.TradeId) {
                data.TradeCode = entry.TradeId;
                data.TradeDescription = entry.TradeDescription;
            }

            data.StartTime = workedWage.startTime;
            data.EndTime = getEndTime(workedWage.startTime, workedWage.workedHours);

            data.WageType = workedWage.wage; //Time, half and double

            data.Shift = self.selectedShift;

            if(entry.IsEmployee) {
                data.DefaultBonuses = GetBonusesTimeEntry(self);
            }

            data.Comment = self.comment;

            return data;
    });
}

function buildEmployeeEquipmentTimeEntry(self) {
    let data = {}
    const entry = self.selectedEntries[0];

    data.TimeEntryId = -1;

    data.DispatchId = self.equipment.id;

    data.DispatchProjectCode = self.dispatchProjectCode;
    data.ActivityCode = self.equipmentActivity.id;

    if (entry.IsEmployee) {
        data.DefaultBonuses = GetBonusesTimeEntry(self);
    }

    if (self.selectedSubProject) {
        data.ProjectCode = self.selectedSubProject.id;
    } else {
        data.DispatchProjectCode = self.dispatchProjectCode;
    }

    data.StartTime = self.startTime;
    data.EndTime = getEndTime(self.startTime, getWorkTime(self, self.equipmentWorkTime));

    data.WageType = enumHelper.wageType().SIMPLE;

    data.Shift = self.selectedShift;

    return data
}

function GetBonusesTimeEntry(self) {
    var bonuses = [];

    self.defaultBonuses.forEach(bonus => {
            // If quantity, get the value
            // If other, get the default value if the checkbox is checked or 0
            bonus.value = bonus.type === self.bonusType.Quantity ? bonus.value : bonus.value ? bonus.defaultValue : 0;
            bonuses.push(bonus);
    });

    return bonuses;
}

function getEndTime(strStartTime, strDurationInHours) {
    return dateHelper.getTime(dateHelper.addHour(getDateTime(strStartTime), strDurationInHours));
}

function buildDataToSend(self) {
    self.dataToSend = [];

    var usedWages =
        _.filter([
            { wage: enumHelper.wageType().SIMPLE, workedHours: self.regularTime, startTime: self.startTime },
            { wage: enumHelper.wageType().OVER, workedHours: self.halfTime, startTime: getEndTime(self.startTime, self.regularTime)},
            { wage: enumHelper.wageType().DOUBLE, workedHours: self.doubleTime, startTime: getEndTime(getEndTime(self.startTime, self.regularTime), self.halfTime) }],
             (wage) => { return wage.workedHours && wage.workedHours > 0 });

     self.selectedEntries.map((entry) => {
         self.dataToSend.push.apply(self.dataToSend, dataEntered(self, entry, usedWages));
    });
    return self.dataToSend;
}

function updateTotalHours(self) {
    self.totalHours = self.regularTime + self.halfTime + self.doubleTime;
    updateEndTime(self);
}

function updateEndTime(self) {
    if (self.startTime !== "") {
        self.endTime = getEndTime(self.startTime, self.regularTime + self.halfTime + self.doubleTime);
    }
}

function getWorkTime(self, time) {
    if (time) {
        return moment(getDateTime(time)).diff(moment(dateHelper.getDate().toISOString()), "minutes") / 60;
    } else {
        return 0;
    }
}

function getDateTime(time) {
    let dateTime = new Date();
    dateTime.setHours(time.split(":")[0]);
    dateTime.setMinutes(time.split(":")[1]);
    return dateTime;
}

function getDefaultShift(self) {
    dispatchTimesheetService.getDefaultShift(self.dispatchProjectCode)
        .done((data) => {
            if (data !== undefined && data !== null) {
                self.selectedShift = data;
            }
        });
}

function initializeStartTime(self) {
    dispatchTimesheetService.getDefaultTimes(self.dispatchProjectCode,
            self.dispatchDate,
            _.pluck(self.selectedEntries, "DispatchId"))
        .done((data) =>{
            if (data.StartTime) {
                data.StartTime = dateHelper.roundTime(data.StartTime, self.increment);
                self.startTime = dateHelper.formatDate("HH:mm", data.StartTime);
            }
            if (data.DefaultShift !== undefined && data.DefaultShift !== null) {
                self.selectedShift = data.DefaultShift;
            }
        });
}

function getId(list, entryValue) {
    return _.first(_.where(list, { text: entryValue })).id;
}

function initializeOptions(self) {
    commonService.getShifts().done((data) => {
        self.shiftList = _.map(data, (shift) => {
            return { id: shift.Id, text: shift.Id + " - " + shift.Description };
        });
        if (self.shiftList.length > 0) {
            self.selectedShift = _.first(self.shiftList).id;
        }
    });

    templateService.getUserTemplateIncrement().done((data) => {
        self.increment = data;
        self.selectOptions = TimeListHelper.loadTimeList(data);
    } );

    let templateConfigs = templateService.getCurrentTemplateConfigs();

    self.activityAvailable = !templateConfigs.ActivityNotAllowedInMobility;

    self.wageTypes = wageSettingHelper.getWageFromTemplateconfigs( "Project", templateConfigs );

    // Default bonus

    self.projectConfigService.GetConfig().done((config) => {
        if (config !== undefined && config !== null) {
            self.applyDefaultBonus = config.ApplyDefaultBonus === self.defaultBonusApplyOption.DuringTransactionEntry;
        }
    });
}

function initializeLastUsedSubProject( self ) {
    var dfd = new jQuery.Deferred();
    var hasSubProjects = projectService.hasSubProjects(self.dispatchProjectCode);
    var lastProjectUsed = projectService.getLastProjectUsed(self.dispatchProjectCode, self.dispatchDate);

    jQuery.when(hasSubProjects, lastProjectUsed).done((hasPrj, lastPrjUsed) => {
        self.showSubProjects = hasPrj;
        if (hasPrj && lastPrjUsed) {
            self.selectedSubProject = { id: lastPrjUsed.Id, text: lastPrjUsed.Id + ' - ' + lastPrjUsed.Description };
        }
        dfd.resolve();
    });

    return dfd;
}

function buildErrors(self) {
    self.errors = [];
    if (!self.selectedEntries || self.selectedEntries.length <= 0) { self.errors.push(resx.localize('err_YouMustSelectPresences')); }
    if (!self.startTime) { self.errors.push(resx.localize('err_YouMustSetStartTime')); }
    if (self.showSubProjects && !self.selectedSubProject) { self.errors.push(resx.localize('err_SubProjectRequired')); }
    if (self.activityAvailable && (!self.selectedActivity || !self.selectedActivity.id)) { self.errors.push(resx.localize('err_ActivityRequired')); }
    if (self.activityAvailable && self.equipment.id && !self.equipmentActivity.id) { self.errors.push(resx.localize('err_EquipmentActivityRequired')); }
    if (!self.totalHours) { self.errors.push(resx.localize('err_TimeSpentRequired')); }
    if (self.equipment.id && !self.equipmentWorkTime) { self.errors.push(resx.localize('err_EquipmentTimeSpentRequired')); }

    return self.errors;
}

@inject(BindingEngine, ProjectTimeEntryHelper, BonusService, ProjectConfigService, DefaultBonusApplyOption, BonusType, DispatchProjectTimeEntryService)
export class EnterTime {
    projectTimeEntryHelper;
    bonusService;
    projectConfigService;
    defaultBonusApplyOption;
    bonusType;
    resx = resx;
    enumHelper = enumHelper;
    @observable selectedTrade = "";
    @observable selectedActivity = "";
    @observable selectedSubProject = "";
    listPresentEmployee = "";
    date = dateHelper.getDate();
    totalHours = 0;
    dataToSend = [];
    selectOptions = [];
    shiftList = [];
    increment = 0;
    errors = [];
    regularTime = 0;
    halfTime = 0;
    doubleTime = 0;
    showSubProjects = false;
    activityAvailable = false;
    wageTypes = [];
    trades = [];

    applyDefaultBonus = false;
    showDefaultBonuses = false;
    defaultBonusesAreEquals = false;
    defaultBonusesFound = false;
    defaultBonuses = [];
    defaultBonusError = "";
    activities = [];

    @bindable
    @observable
    comment = "";

    @bindable selectedEntries = [];
    @bindable dispatchProjectCode;
    @bindable readonly;
    @bindable dispatchModel;
    @bindable dispatchDate;

    @observable startTime = "";
    @observable selectedRegularTime = "";
    @observable selectedHalfTime = "";
    @observable selectedDoubleTime = "";
    @observable selectedShift;
    @observable equipment = "";
    @observable equipmentActivity = "";

    constructor(bindingEngine, projectTimeEntryHelper, bonusService, projectConfigService, defaultBonusApplyOption, bonusType, dispatchProjectTimeEntryService) {
        var self = this;
        this.bindingEngine = bindingEngine;
        this.projectTimeEntryHelper = projectTimeEntryHelper;
        this.bonusService = bonusService;
        this.projectConfigService = projectConfigService;
        this.defaultBonusApplyOption = defaultBonusApplyOption;
        this.bonusType = bonusType;
        this.dispatchProjectTimeEntryService = dispatchProjectTimeEntryService;
        let subscription = this.bindingEngine.collectionObserver(this.selectedEntries).subscribe(this.selectedEntriesChange);
    }

    get emptyDate() {
        return dateHelper.getDate().toISOString();
    }

    created() {
        initializeOptions( this );
    }

    async attached() {
        initializeLastUsedSubProject(this);
        getDefaultShift(this);
        await initActivities(this);
    }

    validateForm() {
        buildErrors(this);
        if (this.errors.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    createDefaultMaSelectElement(id, list) {
        if (!id) { return null; }

        const item = list.find((item) => item.Id === id);
        if (!item) {
            return null
        }

        return { id: item.Id, text: item.Id + " - " + item.Description };
    }

    hasMixOfEntriesWithTimeAndWithoutTime(nbrWithTime, nbrWithoutTime) {
        return nbrWithTime > 0 && nbrWithoutTime > 0;
    }

    breakConditionIsSatisfied(nbrWithTime, nbrWithoutTime, isAllSameDispatchStartTime, isAllSameEntryEndTime) {
        return !this.hasMixOfEntriesWithTimeAndWithoutTime(nbrWithTime, nbrWithoutTime)
        || !isAllSameDispatchStartTime
        || !isAllSameEntryEndTime;
    }

    canUseTheSameStartTime() {
        var count = this.selectedEntries.length;

        if (count < 1) { return false; }
        if (count === 1) { return true; }

        var nbrWithTime = 0;
        var nbrWithoutTime = 0;

        var disptachStartTime = this.selectedEntries[0].DispatchStartTime;
        var previousEntryEndTime = null;
        var isAllSameEntryEndTime = true;
        var isAllSameDispatchStartTime = true;

        for (var i = 0;
            i < count
            && this.breakConditionIsSatisfied(nbrWithTime, nbrWithoutTime, isAllSameDispatchStartTime, isAllSameEntryEndTime);
            i++) {
            var entry = this.selectedEntries[i];

            if (entry.HasTimeEntries) {
                nbrWithTime++;

                if (!previousEntryEndTime) {
                    previousEntryEndTime = entry.LastEntryEndTime;
                    continue;
                }

                isAllSameEntryEndTime = isAllSameEntryEndTime && previousEntryEndTime === entry.LastEntryEndTime;
            } else {
                nbrWithoutTime++;
                isAllSameDispatchStartTime = isAllSameDispatchStartTime && disptachStartTime === entry.DispatchStartTime;
            }

        }
        return !this.hasMixOfEntriesWithTimeAndWithoutTime(nbrWithTime, nbrWithoutTime)
        && isAllSameDispatchStartTime
        && isAllSameEntryEndTime;
    }

    setDefaultActivity() {
        this.selectedActivity = "";

        if (this.selectedEntries.length === 1 && !this.selectedEntries[0].IsEmployee && this.activityAvailable) {
            if (this.selectedEntries[0].DefaultEquipmentActivityCode) {
                this.selectedActivity = this.createDefaultMaSelectElement(this.selectedEntries[0].DefaultEquipmentActivityCode, this.activities);
            }
        } else if (this.activityAvailable && this.allEmployeesAndSameDefaultLaborActivity(this.selectedEntries)) {
            this.selectedActivity = this.createDefaultMaSelectElement(this.selectedEntries[0].DefaultLaborActivityCode, this.activities);
        }
    }

    allEmployeesAndSameDefaultLaborActivity(array) {
        if (array.length === 0) {
            return false;
        }

        return array.every(function (entry) {
            return entry.IsEmployee && entry.DefaultLaborActivityCode === array[0].DefaultLaborActivityCode;
        });
    }

    async selectedEntriesChanged() {

        if (this.selectedEntries.length > 0) {
            await initActivities(this);
        }
       
        this.setDefaultActivity();

        if (this.selectedEntries.length === 1 && this.selectedEntries[0]) {
            var result = await this.dispatchProjectTimeEntryService.GetSeperateTimeLinkedEquipmentDispatches(_.first(this.selectedEntries).DispatchId);
            this.showEquipmentSection = result.length > 0;
        } else {
            this.showEquipmentSection = false;
        }

        if (this.canUseTheSameStartTime()) {
            var self = this;
            initializeStartTime(this);

            // only one entry and is employe
            if (_.first(this.selectedEntries).IsEmployee) {
                await initializeTrade(this, _.first(this.selectedEntries).DispatchId);
            }
        }
        else {
            this.startTime = _.first( this.selectOptions).text;
        }
        await ShowDefaultBonuses(this);
    }

    get isEmployee() {
        if ((this.selectedEntries.length === 1) && _.first(this.selectedEntries).IsEmployee) {
             return true;
        }
        else {
            return false;
        }
    }

    async startTimeChanged(newValue) {
        updateEndTime(this);
        await ShowDefaultBonuses(this);
    }

    async selectedRegularTimeChanged(newValue) {
        this.regularTime = getWorkTime(this, newValue);
        updateTotalHours(this);
        await ShowDefaultBonuses(this);
    }

    async selectedHalfTimeChanged(newValue) {
        this.halfTime = getWorkTime(this, newValue);
        updateTotalHours(this);
        await ShowDefaultBonuses(this);
    }

    async selectedDoubleTimeChanged(newValue) {
        this.doubleTime = getWorkTime(this, newValue);
        updateTotalHours(this);
        await ShowDefaultBonuses(this);
    }

    async selectedActivityChanged() {
        await ShowDefaultBonuses(this);
    }

    async selectedShiftChanged() {
        await ShowDefaultBonuses(this);
    }

    async selectedTradeChanged() {
        if (!this.selectedEntries) {
            this.selectedActivity = "";
            return;
        }

        if (!this.activityAvailable) {
            const employeeEntries = this.selectedEntries.filter(entry => entry.IsEmployee);

            if (employeeEntries.length > 1) {
                this.selectedActivity = "";
            }

            if (employeeEntries.length < 2 && this.selectedTrade !== "") {
                if (this.selectedTrade.DefaultActivityCode) {
                    this.selectedActivity = { id: this.selectedTrade.DefaultActivityCode }
                } else if (this.trades && this.trades.length > 0) {
                    const trade = this.trades.find(tr => tr.Id == this.selectedTrade.id);
                    if (trade !== null && trade.DefaultActivityCode !== "") {
                        this.selectedActivity = { id: trade.DefaultActivityCode }
                    }
                }
            }
        }

        await ShowDefaultBonuses(this);
    }

    async selectedSubProjectChanged() {
        if (this.selectedEntries && this.selectedEntries.length > 0) {
            await initActivities(this);
            this.setDefaultActivity();
        }
    }

    async save(forceSave) {
        if (!this.validateForm()) {
            notificationHelper.showValidationError(() => { return this.errors; });
            return;
        }

        const dispatchTemplateId = settingHelper.getSelectedDispatchModel();

        const dataToSend = buildDataToSend(this);

        if (this.showEquipmentSection && (this.equipment && this.equipment.id)) {
            dataToSend.push(buildEmployeeEquipmentTimeEntry(this));
        }

        const result = await dispatchTimesheetService.setTimeEntries(dataToSend, forceSave, dispatchTemplateId, this.dispatchProjectCode, this.dispatchDate);

        if (!result.HasConflictsOnCurrentProject && !result.HasConflictsOnOtherProjects) {
            notificationHelper.showSuccess(resx.localize("SaveSuccessful"));
            clearForm(this);
            routerHelper.navigateSelf();
        } else {
            const conflictsMessage = this.projectTimeEntryHelper.buildConflictsMessage(result.Entries);

            const success = await notificationHelper.showDialogYesNo(conflictsMessage, resx.localize("War_ValuesEnteredInTheSameRange"), false);
            if (success === true) {
                this.save(true); //with force save
            }
        }
    }

    equipmentChanged(item) {
        if(item === "" || !item || !item.data) { return; }
        if (item.data.data.DefaultActivityId) {
            const defaultActivity = this.activities.find(x => x.Id === item.data.data.DefaultActivityId);
            if (defaultActivity) {
                this.equipmentActivity = {id: defaultActivity.Id, text: defaultActivity.Id + " - " + defaultActivity.Description};
            }
        }
    }

    getTrades = {
        transport: (params, success, failure) => {
            lookupTrades(this, params, success, failure);
        }
    }

    getActivity = {
        transport: (params, success, failure) => {
            lookupActivity(this, params, success, failure);
        }
    }

    getProjects = {
        transport: (params, success, failure) => {
            projectService.getSubProjects(this.dispatchProjectCode, params.data.filter, params.data.page)
                .done(function (data) {
                    return success(data);
                })
                .fail(failure);
        }
    }

    getEquipments = {
        transport: (params, success) => {
            if (this.selectedEntries.length > 1 && !this.selectedEntries[0]) {
                return;
            }
            this.dispatchProjectTimeEntryService.GetSeperateTimeLinkedEquipmentDispatches(_.first(this.selectedEntries).DispatchId, params.data.filter, params.data.page)
            .done(function (data) {
                return success(data);
            });
        },
        mapResults: function(item) {
            return { id: item.DispatchId, text: `${item.Code} - ${item.Description}`, data: item };
        }
    }

    validWage(wage) {
        return _.contains( this.wageTypes, wage );
    }
}